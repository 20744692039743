import { AnswerModel } from 'core/models/elements/answers/answer.abstract.model';

import { pascalToCamel } from 'utils/common.util';

/**
 * @class Defines an sorting answer corresponding to an sorting question.
 */
export class SortingQuestionAnswerModel extends AnswerModel {
  /**
   * @constructor Creates an instance of an sorting answer built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.values = bo.values;
  }

  static instantiateFromApi(data) {
    const bo = pascalToCamel(data);
    return new SortingQuestionAnswerModel(bo);
  }
}
