import { GroupQuestionModel } from 'core/models/elements/questions/groupQuestion.model';
import { GroupQuestionAnswerModel } from 'core/models/elements/answers/groupQuestionAnswer.model';
import { GroupQuestionService } from 'core/services/question/groupQuestion.service';
import { ExplodedTextQuestionModel } from 'core/models/elements/questions/explodedTextQuestion.model';
import { ExplodedTextQuestionAnswerModel } from 'core/models/elements/answers/explodedTextQuestionAnswer.model';
import { ExplodedTextQuestionService } from 'core/services/question/explodedTextQuestion.service';
import { FreeTextQuestionModel } from 'core/models/elements/questions/freeTextQuestion.model';
import { FreeTextQuestionAnswerModel } from 'core/models/elements/answers/freeTextQuestionAnswer.model';
import { FreeTextQuestionService } from 'core/services/question/freeTextQuestion.service';
import { SortingQuestionAnswerModel } from 'core/models/elements/answers/sortingQuestionAnswer.model';
import { SortingQuestionService } from 'core/services/question/sortingQuestion.service';
import { SortingQuestionModel } from 'core/models/elements/questions/sortingQuestion.model';
import { AudioQuestionService } from 'core/services/question/audioQuestion.service';
import { AudioQuestionModel } from 'core/models/elements/questions/audioQuestion.model';
import { AudioQuestionAnswerModel } from 'core/models/elements/answers/audioQuestionAnswer.model';
import { HoleQuestionAnswerModel } from 'core/models/elements/answers/holeQuestionAnswer.model';
import { HoleQuestionModel } from 'core/models/elements/questions/holeQuestion.model';
import { HoleQuestionService } from 'core/services/question/holeQuestion.service';

import { CraftImageQuestionAnswerModel } from 'core/models/elements/answers/craftImageQuestionAnswer.model';
import { CraftImageQuestionModel } from 'core/models/elements/questions/craftImageQuestion.model';
import { CraftImageQuestionService } from 'core/services/question/craftImageQuestion.service';

import { MultipleChoicesQuestionModel } from 'core/models/elements/questions/multipleChoicesQuestion.model';
import { MultipleChoicesQuestionService } from 'core/services/question/multipleChoicesQuestion.service';
import { MultipleChoicesQuestionAnswerModel } from 'core/models/elements/answers/multipleChoicesQuestionAnswer.model';
import { MapQuestionModel } from 'core/models/elements/questions/mapQuestion.model';
import { MapQuestionService } from 'core/services/question/mapQuestion.service';
import { MapQuestionAnswerModel } from 'core/models/elements/answers/mapQuestionAnswer.model';
import { PollQuestionModel } from 'core/models/elements/questions/pollQuestion.model';
import { PollQuestionService } from 'core/services/question/pollQuestion.service';
import { PollQuestionAnswerModel } from 'core/models/elements/answers/pollQuestionAnswer.model';
import { ScormQuestionModel } from 'core/models/elements/questions/scormQuestion.model';
import { ScormQuestionService } from 'core/services/question/scormQuestion.service';
import { ScormQuestionAnswerModel } from 'core/models/elements/answers/scormQuestionAnswer.model';
import { H5PQuestionModel } from 'core/models/elements/questions/h5pQuestion.model';
import { H5PQuestionService } from 'core/services/question/h5pQuestion.service';
import { H5PQuestionAnswerModel } from 'core/models/elements/answers/h5pQuestionAnswer.model';
import { AssociateQuestionModel } from 'core/models/elements/questions/associateQuestion.model';
import { AssociateQuestionService } from 'core/services/question/associateQuestion.service';
import { AssociateQuestionAnswerModel } from 'core/models/elements/answers/associateQuestionAnswer.model';

import { LtiQuestionModel } from 'core/models/elements/questions/ltiQuestion.model';
import { LtiQuestionService } from 'core/services/question/ltiQuestion.service';
import { LtiQuestionAnswerModel } from 'core/models/elements/answers/ltiQuestionAnswer.model';

import { activityDisplayCorrectionMode } from './activity.util';
import { HotspotQuestionModel } from 'core/models/elements/questions/hotspotQuestion.model';
import { HotspotQuestionService } from 'core/services/question/hotspotQuestion.service';
import { HotspotQuestionAnswerModel } from 'core/models/elements/answers/hotspotQuestionAnswer.model';
import { t } from 'i18next';
import { NotEvaluatedQuestionAnswerModel } from 'core/models/elements/answers/notEvaluatedQuestionAnswer.model';
import { NotEvaluatedQuestionService } from 'core/services/question/notEvaluatedQuestion.service';

export const tactileoTypes = {
  GROUP_QUESTION:
    'Tactileo.ResourceBank.Entities.Elements.Questions.GroupQuestion',
  LTI_QUESTION: 'Tactileo.ResourceBank.Entities.Elements.Questions.LtiQuestion',
  H5P_QUESTION: 'Tactileo.ResourceBank.Entities.Elements.Questions.H5pQuestion',
  SCORM_QUESTION:
    'Tactileo.ResourceBank.Entities.Elements.Questions.ScormQuestion',
  MAP_QUESTION: 'Tactileo.ResourceBank.Entities.Elements.Questions.MapQuestion',
  SORTING_QUESTION:
    'Tactileo.ResourceBank.Entities.Elements.Questions.SortingQuestion',
  HOLE_QUESTION:
    'Tactileo.ResourceBank.Entities.Elements.Questions.HoleQuestion',
  AUDIO_QUESTION:
    'Tactileo.ResourceBank.Entities.Elements.Questions.AudioQuestion',
  MULTIPLE_CHOICES_QUESTION:
    'Tactileo.ResourceBank.Entities.Elements.Questions.MultipleChoicesQuestion',
  EXPLODED_TEXT_QUESTION:
    'Tactileo.ResourceBank.Entities.Elements.Questions.ExplodedTextQuestion',
  ASSOCIATE_QUESTION:
    'Tactileo.ResourceBank.Entities.Elements.Questions.AssociateQuestion',
  FREE_TEXT_QUESTION:
    'Tactileo.ResourceBank.Entities.Elements.Questions.FreeTextQuestion',
  CRAFT_IMAGE_QUESTION:
    'Tactileo.ResourceBank.Entities.Elements.Questions.CraftImageQuestion',
  POLL_QUESTION:
    'Tactileo.ResourceBank.Entities.Elements.Questions.PollQuestion',
  HOTSPOT_QUESTION:
    'Tactileo.ResourceBank.Entities.Elements.Questions.HotspotQuestion',
  RICH_TEXT_CONTENT:
    'Tactileo.ResourceBank.Entities.Elements.Contents.RichTextContent',
  INTERNET_PAGE_CONTENT:
    'Tactileo.ResourceBank.Entities.Elements.Contents.HtmlLinkContent',
  PDF_PAGE_CONTENT:
    'Tactileo.ResourceBank.Entities.Elements.Contents.PdfContent',
  VIDEO_PAGE_CONTENT:
    'Tactileo.ResourceBank.Entities.Elements.Contents.VideoContent',
  GRAIN_CONTENT:
    'Tactileo.ResourceBank.Entities.Elements.Contents.GrainContent',
};

export const tactileoAnswersDTOTypes = {
  ANSWER_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.PollAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  GROUP_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.GroupAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  EXPLODED_TEXT_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.ExplodedTextAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  SORTING_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.SortingAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  FREE_TEXT_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.FreeTextAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  HOLE_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.HoleAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  AUDIO_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.AudioAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  MULTIPLE_CHOICES_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.MultipleChoicesAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  ASSOCIATE_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.AssociateAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  MAP_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.MapAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  POLL_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.PollAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  SCORM_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.ScormAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  H5P_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.H5PAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  HOTSPOT_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.HotspotAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  CRAFT_IMAGE_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.CraftImageAnswerInputCommandDTO, Tactileo.Webservices.DTO',
  LTI_QUESTION:
    'Tactileo.Webservices.DTO.Resources.Commands.ActivitySession.Elements.Answers.LtiAnswerInputCommandDTO, Tactileo.Webservices.DTO',
};

export const activityItemStates = {
  NOT_ANSWERED: 'NOT_ANSWERED',
  ANSWERED: 'ANSWERED',
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
  PENDING: 'PENDING',
};

export const activityItemEvaluationMethod = {
  NONE: 0,
  AUTO: 1,
  MANUAL: 2,
};

export const mcqDisplay = {
  LARGE_TEXT: 0,
  LARGE_IMG: 1,
  COMPACT: 2,
};

export const evaluate = (item, answer) => {
  if (item instanceof GroupQuestionModel) {
    return new GroupQuestionService().evaluate(item, answer);
  } else if (item instanceof ExplodedTextQuestionModel) {
    return new ExplodedTextQuestionService().evaluate(item, answer);
  } else if (item instanceof FreeTextQuestionModel) {
    return new FreeTextQuestionService().evaluate(item, answer);
  } else if (item instanceof SortingQuestionModel) {
    return new SortingQuestionService().evaluate(item, answer);
  } else if (item instanceof AudioQuestionModel) {
    return new AudioQuestionService().evaluate(item, answer);
  } else if (item instanceof HoleQuestionModel) {
    return new HoleQuestionService().evaluate(item, answer);
  } else if (item instanceof CraftImageQuestionModel) {
    return new CraftImageQuestionService().evaluate(item, answer);
  } else if (item instanceof MultipleChoicesQuestionModel) {
    return new MultipleChoicesQuestionService().evaluate(item, answer);
  } else if (item instanceof MapQuestionModel) {
    return new MapQuestionService().evaluate(item, answer);
  } else if (item instanceof PollQuestionModel) {
    return new PollQuestionService().evaluate(item, answer);
  } else if (item instanceof H5PQuestionModel) {
    return new H5PQuestionService().evaluate(item, answer);
  } else if (item instanceof ScormQuestionModel) {
    return new ScormQuestionService().evaluate(item, answer);
  } else if (item instanceof AssociateQuestionModel) {
    return new AssociateQuestionService().evaluate(item, answer);
  } else if (item instanceof HotspotQuestionModel) {
    return new HotspotQuestionService().evaluate(item, answer);
  } else if (item instanceof LtiQuestionModel) {
    return new LtiQuestionService().evaluate(item, answer);
  }
};

export const answer = (activitySessionId, itemId, answer, env, token) => {
  if (answer instanceof GroupQuestionAnswerModel) {
    return new GroupQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof NotEvaluatedQuestionAnswerModel) {
    return new NotEvaluatedQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof ExplodedTextQuestionAnswerModel) {
    return new ExplodedTextQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof FreeTextQuestionAnswerModel) {
    return new FreeTextQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof SortingQuestionAnswerModel) {
    return new SortingQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof HoleQuestionAnswerModel) {
    return new HoleQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof CraftImageQuestionAnswerModel) {
    return new CraftImageQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof MultipleChoicesQuestionAnswerModel) {
    return new MultipleChoicesQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof MapQuestionAnswerModel) {
    return new MapQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof PollQuestionAnswerModel) {
    return new PollQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof H5PQuestionAnswerModel) {
    return new H5PQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof ScormQuestionAnswerModel) {
    return new ScormQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof AssociateQuestionAnswerModel) {
    return new AssociateQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof HotspotQuestionAnswerModel) {
    return new HotspotQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof AudioQuestionAnswerModel) {
    return new AudioQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  } else if (answer instanceof LtiQuestionAnswerModel) {
    return new LtiQuestionService(env, token).answer(
      activitySessionId,
      itemId,
      answer,
    );
  }
};

export const prepareAnswerAndEvaluation = (item, tags) => {
  if (item instanceof GroupQuestionModel) {
    return new GroupQuestionService().prepareAnswerAndEvaluation(item, tags);
  } else if (item instanceof ExplodedTextQuestionModel) {
    return new ExplodedTextQuestionService().prepareAnswerAndEvaluation(
      item,
      tags,
    );
  } else if (item instanceof FreeTextQuestionModel) {
    return new FreeTextQuestionService().prepareAnswerAndEvaluation(item, tags);
  } else if (item instanceof SortingQuestionModel) {
    return new SortingQuestionService().prepareAnswerAndEvaluation(item, tags);
  } else if (item instanceof HoleQuestionModel) {
    return new HoleQuestionService().prepareAnswerAndEvaluation(item, tags);
  } else if (item instanceof CraftImageQuestionModel) {
    return new CraftImageQuestionService().prepareAnswerAndEvaluation(
      item,
      tags,
    );
  } else if (item instanceof MultipleChoicesQuestionModel) {
    return new MultipleChoicesQuestionService().prepareAnswerAndEvaluation(
      item,
      tags,
    );
  } else if (item instanceof MapQuestionModel) {
    return new MapQuestionService().prepareAnswerAndEvaluation(item, tags);
  } else if (item instanceof PollQuestionModel) {
    return new PollQuestionService().prepareAnswerAndEvaluation(item, tags);
  } else if (item instanceof ScormQuestionModel) {
    return new ScormQuestionService().prepareAnswerAndEvaluation(item, tags);
  } else if (item instanceof H5PQuestionModel) {
    return new H5PQuestionService().prepareAnswerAndEvaluation(item, tags);
  } else if (item instanceof AssociateQuestionModel) {
    return new AssociateQuestionService().prepareAnswerAndEvaluation(
      item,
      tags,
    );
  } else if (item instanceof HotspotQuestionModel) {
    return new HotspotQuestionService().prepareAnswerAndEvaluation(item, tags);
  } else if (item instanceof AudioQuestionModel) {
    return new AudioQuestionService().prepareAnswerAndEvaluation(item, tags);
  } else if (item instanceof LtiQuestionModel) {
    return new LtiQuestionService().prepareAnswerAndEvaluation(item, tags);
  }
};

export const getItemResultMode = (item, activity) => {
  if (!item) {
    return activity.itemsDisplayCorrectionMode;
  }
  if (item.evaluationMethod === activityItemEvaluationMethod.NONE) {
    return activityDisplayCorrectionMode.NONE;
  }
  if (
    item.evaluationMethod === activityItemEvaluationMethod.MANUAL &&
    item.showResultMode !== activityDisplayCorrectionMode.NONE
  ) {
    return activityDisplayCorrectionMode.DISPLAY_RESULT;
  }
  return item.showResultMode !== null
    ? item.showResultMode
    : activity.itemsDisplayCorrectionMode;
};

export const responseAnswerStatus = {
  FULFILLED: 'fulfilled',
};

export const activityResultState = {
  ERROR: 'error',
  SUCCESS: 'success',
  DISABLED: 'disabled',
  DEFAULT: 'default',
};

export const hiddenPageElementsType = [
  tactileoTypes.RICH_TEXT_CONTENT,
  tactileoTypes.PDF_PAGE_CONTENT,
  tactileoTypes.INTERNET_PAGE_CONTENT,
  tactileoTypes.VIDEO_PAGE_CONTENT,
  tactileoTypes.SCORM_QUESTION,
  tactileoTypes.H5P_QUESTION,
  tactileoTypes.GRAIN_CONTENT,
];

export const noAvatarElementsType = [tactileoTypes.LTI_QUESTION];

export const subtitlesAndTranscriptionLanguage = {
  FRENCH: 'fr-FR',
  ENGLISH: 'en-US',
  SPANISH: 'es-ES',
  PORTUGUESE: 'pt-BR',
};

export const languageCodeToTitle = (language) => {
  switch (language) {
    case subtitlesAndTranscriptionLanguage.FRENCH:
      return t('language.french');
    case subtitlesAndTranscriptionLanguage.ENGLISH:
      return t('language.english');
    case subtitlesAndTranscriptionLanguage.SPANISH:
      return t('language.spanish');
    case subtitlesAndTranscriptionLanguage.PORTUGUESE:
      return t('language.portuguese');
    default:
      return language;
  }
};

export const findSelectedMenuItem = (id, arr) => {
  return arr.reduce((acc, menuElem) => {
    if (acc) return acc;
    if (menuElem.id === id) return menuElem;
    if (menuElem.items) return findSelectedMenuItem(id, menuElem.items);
  }, null);
};

export const findActiveMenuItem = (id, arr) => {
  return arr.reduce((acc, menuElem) => {
    if (acc) return acc;
    if (arr.some((item) => item.id === id)) {
      return arr?.find(
        ({ isItemSelected, isChecked }) => isChecked || isItemSelected,
      );
    }
    if (menuElem.items) return findActiveMenuItem(id, menuElem.items);
  }, null);
};

export const calcStickerElementsWidth = ({
  isAudio,
  isImg,
  isAnswered,
  isDisabled = false,
}) => {
  const DEFAULT_PADDING = 5;
  const DEFAULT_ELEMENT_WIDTH = 48;
  const DEFAULT_RESULT_ICON = 40;
  let audioButtonWidth, iconWidth, deafultPadding, resultIconWidth;

  audioButtonWidth = isAudio ? DEFAULT_ELEMENT_WIDTH : 0;
  iconWidth = isImg ? DEFAULT_ELEMENT_WIDTH : 0;
  deafultPadding = isImg && isAudio ? DEFAULT_PADDING : 0;
  resultIconWidth = isAnswered && !isDisabled ? DEFAULT_RESULT_ICON : 0;

  return audioButtonWidth + iconWidth + deafultPadding + resultIconWidth;
};
