import { createSlice } from '@reduxjs/toolkit';
import {
  findActiveMenuItem,
  findSelectedMenuItem,
} from 'utils/activityItem.util';

const accessabilityMenuModel = {
  accessibilityItems: [],
  isOpenAccessibilityMenu: false,
};

export const accessibilityMenuSlice = createSlice({
  name: 'accessibility',
  initialState: accessabilityMenuModel,
  reducers: {
    setAccesibilityItems: (state, action) => ({
      ...state,
      accessibilityItems: action.payload,
    }),
    updateMenuSelectedItem: (state, action) => {
      const menuData = findSelectedMenuItem(
        action.payload,
        state.accessibilityItems,
      );
      const activeItemData = findActiveMenuItem(
        action.payload,
        state.accessibilityItems,
      );
      if (activeItemData?.isItemSelected) {
        activeItemData.isItemSelected = false;
      }
      menuData.isItemSelected = true;
    },
    handleRadioButtonState: (state, action) => {
      const activeItem = findActiveMenuItem(
        action.payload,
        state.accessibilityItems,
      );
      const item = findSelectedMenuItem(
        action.payload,
        state.accessibilityItems,
      );

      if (activeItem?.isChecked) {
        activeItem.isChecked = !activeItem.isChecked;

        if (item?.id === action.payload) {
          item.isChecked = !item.isChecked;
        }
      }
    },
    updateToggleButtonState: (state, action) => {
      const item = findSelectedMenuItem(
        action.payload,
        state.accessibilityItems,
      );

      if (item?.id === action.payload) {
        item.isToggle = !item.isToggle;
      }
    },
    updateMenuOpenState: (state) => {
      state.isOpenAccessibilityMenu = !state.isOpenAccessibilityMenu;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAccesibilityItems,
  updateMenuSelectedItem,
  handleRadioButtonState,
  updateToggleButtonState,
  updateMenuOpenState,
} = accessibilityMenuSlice.actions;

export default accessibilityMenuSlice.reducer;
