/* eslint-disable max-classes-per-file */
import { QuestionEvaluationModel } from 'core/models/elements/evaluations/questionEvaluation.abstract.model';

/**
 * @class Defines an evaluation of an exploded text question
 */
export class ExplodedTextEvaluationModel extends QuestionEvaluationModel {
  /**
   * @constructor Creates an instance of exploded text evaluation.
   * @param {explodedTextQuestion} explodedTextQuestion is the element to be evaluated.
   */
  constructor(explodedTextQuestion) {
    super(explodedTextQuestion);
    this.results = [];
  }
}

/**
 * @class Defines an evaluation result for a group question item
 */
export class ExplodedTextEvaluationResultModel {
  /**
   * @constructor Creates an instance of group evaluation result.
   */
  constructor() {
    this.text = '';
    this.itemId = null;
    this.isCorrect = false;
  }
}
