import {
  DisplayLarge,
  DisplayMedium,
  DisplaySmall,
  DisplayXSmall,
  DisplayXXSmall,
  HeadlineLarge,
  HeadlineMedium,
  HeadlineSmallRegular,
  HeadlineSmallBold,
  HeadlineXSmall,
  HeadlineXXSmall,
  TitleLargeRegular,
  TitleLargeBold,
  TitleLargeItalic,
  TitleMediumRegular,
  TitleMediumBold,
  TitleMediumItalic,
  TitleSmallRegular,
  TitleSmallBold,
  TitleSmallItalic,
  TitleXSmallRegular,
  TitleXSmallBold,
  TitleXSmallItalic,
  TitleXXSmallRegular,
  TitleXXSmallBold,
  TitleXXSmallItalic,
  BodyLargeRegular,
  BodyLargeMedium,
  BodyLargeBold,
  BodyMediumRegular,
  BodyMediumMedium,
  BodyMediumBold,
  BodySmallRegular,
  BodySmallMedium,
  BodySmallBold,
  BodyXSmallRegular,
  BodyXSmallMedium,
  BodyXSmallBold,
  LabelLarge,
  LabelMedium,
  LabelSmall,
  LabelXSmall,
  LabelXXSmall,
} from '@maskott/tactileo.designsystem';

export const MILLISECONDS_IN_SECOND = 1000;

export const QUIZ_ERROR = {
  ITEM_NOT_FOUND: 'Item_not_in_this_quiz',
};

export const AUTH_STATUS = {
  NONE: 'NONE',
  OPEN: 'OPEN_SESSION',
  IN_PROGRESS: 'IN_PROGRESS',
  DONE: 'DONE',
  FAILED: 'FAILED',
};

export const ACTIVITY_SESSION_STATUS = {
  NONE: 'NONE',
  IN_PROGRESS: 'GETTING_SESSION_IN_PROGRESS',
  SESSION_RECEIVED: 'SESSION_RECEIVED',
  FAILED: 'FAILED',
};

export const AVATAR_CATEGORY = {
  HINT: 'Hint',
  TIMER: 'Timer',
  VALIDATION: 'Validation',
  WELCOME: 'Welcome',
  CORRECT: 'correct',
  INCORRECT: 'Incorrect',
  INCOMPLETE: 'Incomplete',
  WAITING_EVALUATION: 'WaitingEvalution',
  HOTSPOT: 'HotSpot',
};

export const HEADER_HEIGHT = 84;
export const HEADER_MOBILE_HEIGHT = 48;

export const MAX_RECORDER_TIME = 5;

export const LOCALE_COMPARE_SENSITIVITY = {
  VARIANT: 'variant',
  BASE: 'base',
  CASE: 'case',
  ACCENT: 'accent',
};

export const DEFAULT_LOCALE = 'fr';

export const QUESTION_STATE = {
  DEFAULT: 'default',
  ERROR: 'error',
  SUCCESS: 'success',
  EMPTY: 'empty',
  DISABLED: 'disabled',
};

export const EVALUATION_STATUS = {
  NONE: 'NONE',
};

// Enum pour les tailles des composants
export const COMPONENT_SIZE = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
  XSMALL: 'xsmall',
  XXSMALL: 'xxsmall',
};

export const LICENSE_TYPES = {
  CC_BY_SA: 'CC-BY-SA',
  CC_BY_ND: 'CC-BY-ND',
  CC_BY_NC_SA: 'CC-BY-NC-SA',
  CC_0: 'CC-0',
  CC_BY: 'CC-BY',
  CC_BY_NC: 'CC-BY-NC',
  CC_BY_NC_ND: 'CC-BY-NC-ND',
  PUBLIC_DOMAIN: 'PublicDomain',
  SOME_RIGHTS_RESERVED_DOMAIN: 'SomeRightsReserved',
  ALL_RIGHTS_RESERVED: 'AllRightsReserved',
  FREE_FOR_EDUCATION: 'FreeForEducationalAndNC',
  MASKOTT_EDITION: 'MaskottEdition',
};

// Enum pour les types et tailles de police
export const FONT_TYPE_SIZE = {
  DISPLAY: {
    LARGE: DisplayLarge(),
    MEDIUM: DisplayMedium(),
    SMALL: DisplaySmall(),
    XSMALL: DisplayXSmall(),
    XXSMALL: DisplayXXSmall(),
  },
  HEADLINE: {
    LARGE: {
      REGULAR: HeadlineLarge(),
      BOLD: HeadlineLarge(),
    },
    MEDIUM: {
      REGULAR: HeadlineMedium(),
      BOLD: HeadlineMedium(),
    },
    SMALL: {
      REGULAR: HeadlineSmallRegular(),
      BOLD: HeadlineSmallBold(),
    },
    XSMALL: {
      REGULAR: HeadlineXSmall(),
      BOLD: HeadlineXSmall(),
    },
    XXSMALL: {
      REGULAR: HeadlineXXSmall(),
      BOLD: HeadlineXXSmall(),
    },
  },
  TITLE: {
    LARGE: {
      REGULAR: TitleLargeRegular(),
      BOLD: TitleLargeBold(),
      ITALIC: TitleLargeItalic(),
    },
    MEDIUM: {
      REGULAR: TitleMediumRegular(),
      BOLD: TitleMediumBold(),
      ITALIC: TitleMediumItalic(),
    },
    SMALL: {
      REGULAR: TitleSmallRegular(),
      BOLD: TitleSmallBold(),
      ITALIC: TitleSmallItalic(),
    },
    XSMALL: {
      REGULAR: TitleXSmallRegular(),
      BOLD: TitleXSmallBold(),
      ITALIC: TitleXSmallItalic(),
    },
    XXSMALL: {
      REGULAR: TitleXXSmallRegular(),
      BOLD: TitleXXSmallBold(),
      ITALIC: TitleXXSmallItalic(),
    },
  },
  BODY: {
    LARGE: {
      REGULAR: BodyLargeRegular(),
      MEDIUM: BodyLargeMedium(),
      BOLD: BodyLargeBold(),
    },
    MEDIUM: {
      REGULAR: BodyMediumRegular(),
      MEDIUM: BodyMediumMedium(),
      BOLD: BodyMediumBold(),
    },
    SMALL: {
      REGULAR: BodySmallRegular(),
      MEDIUM: BodySmallMedium(),
      BOLD: BodySmallBold(),
    },
    XSMALL: {
      REGULAR: BodyXSmallRegular(),
      MEDIUM: BodyXSmallMedium(),
      BOLD: BodyXSmallBold(),
    },
  },
  LABEL: {
    LARGE: LabelLarge(),
    MEDIUM: LabelMedium(),
    SMALL: LabelSmall(),
    XSMALL: LabelXSmall(),
    XXSMALL: LabelXXSmall(),
  },
};
