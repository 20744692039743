/* eslint-disable max-classes-per-file */
import { AnswerCommand } from 'core/commands/elements/answers/answer.abstract.command';
import { TagCommand } from 'core/commands/elements/components/tag.command';
import { tactileoAnswersDTOTypes } from 'utils/activityItem.util';

export class HoleQuestionAnswerCommand extends AnswerCommand {
  constructor(holeQuestionAnswer) {
    super(tactileoAnswersDTOTypes.HOLE_QUESTION, holeQuestionAnswer);
    this.answers = holeQuestionAnswer.answers
      .filter((a) => a)
      .map((a) => new TagCommand(a));
  }
}
