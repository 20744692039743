/**
 * @class Defines an area in the context of a hotspot question.
 */
export class AreaModel {
  /**
   * @constructor Creates an instance of an area built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    this.id = bo.id;
    this.x = bo.x;
    this.y = bo.y;
    this.width = bo.width;
    this.height = bo.height;
  }

  matchCoordinates(x, y) {
    return (
      x >= this.x &&
      x <= this.x + this.width &&
      y >= this.y &&
      y <= this.y + this.height
    );
  }
}
