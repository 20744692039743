/* eslint-disable max-classes-per-file */
import uniqid from 'uniqid';
import { QuestionModel } from 'core/models/elements/questions/question.abstract.model';
import { TagModel } from 'core/models/elements/components/tag.model';

/**
 * @class Defines a group question.
 */
export class GroupQuestionModel extends QuestionModel {
  /**
   * @constructor Creates an instance of a group question built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.groups = bo.groups
      ? bo.groups.map((group) => new GroupModel(group))
      : undefined;
    this.noEllipsis = bo.no_ellipsis;
  }
}

/**
 * @class Defines a group in the context of a group question.
 */
export class GroupModel {
  /**
   * @constructor Creates an instance of a group built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    this.id = uniqid();
    this.text = bo.text;
    this.urlImage = bo.url_image;
    this.cssClass = bo.css_class;
    this.items = bo.items
      ? bo.items.map((item) => new TagModel(item))
      : undefined;
  }
}
