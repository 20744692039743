import { HintModel } from 'core/models/elements/hint.model';
import { RulesModel } from 'core/models/elements/rules.model';
import { TriggersModel } from 'core/models/elements/triggers.model';

/**
 * @class Defines an activity item.
 * The class is abstract and should be extended.
 */
export class ActivityItemModel {
  /**
   * @constructor Creates an instance of an activity item built from an original data from the API.
   * @param {bo} bo The basic object comes from API.
   */
  constructor(bo) {
    if (this.constructor === ActivityItemModel) {
      throw new TypeError(
        'Abstract class "ActivityItemModel" cannot be instantiated directly',
      );
    }

    this.id = bo.id;
    this.title = bo.title;
    this.itemType = bo.item_type;
    this.hints = bo.hints?.map((x) => new HintModel(x));
    this.rules = bo.rules?.map((x) => new RulesModel(x));
    this.triggers = bo.triggers?.map((x) => new TriggersModel(x));
    this.language = bo.language;
    this.unlockHintAfterDelay = bo.unlock_hint_after_delay
      ? bo.unlock_hint_after_delay
      : null;
    this.unlockHintAfterIncorrectAnswer = bo.unlock_hint_after_incorrect_answer;
    this.timeLimit = bo.time_limit > 0 ? bo.time_limit : null;
    this.evaluationMethod = bo.evaluation_method;
    this.displayCorrectionMode = bo.show_result_mode;
    this.correctMessage = bo.correct_message;
    this.incorrectMessage = bo.incorrect_message;
  }
}
