import { getApiUrlFromEnv, getOrchardUrlFromEnv } from 'utils/environment.util';

export class BaseService {
  constructor(env = 'LOCAL') {
    if (this.constructor === BaseService) {
      throw new TypeError(
        'Abstract class "BaseService" cannot be instantiated directly',
      );
    }

    this.apiUrl = getApiUrlFromEnv(env);
    this.orchardUrl = getOrchardUrlFromEnv(env);

    this.authorization = {
      Authorization: `Bearer ${sessionStorage.getItem('token')}`,
      'Content-Type': 'application/json',
    };
  }
}
