/* eslint-disable eqeqeq */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import { NotEvaluatedQuestionAnswerModel } from 'core/models/elements/answers/notEvaluatedQuestionAnswer.model';
import { NotEvaluatedQuestionAnswerCommand } from 'core/commands/elements/answers/notEvaluatedQuestionAnswer.command';
import { EVALUATION_STATUS } from '../../../constants';

export class NotEvaluatedQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
    this.evaluationMethod = EVALUATION_STATUS.NONE;
  }

  async answer(activitySessionId, activityItemId, notEvaluatedQuestionAnswer) {
    if (
      !(notEvaluatedQuestionAnswer instanceof NotEvaluatedQuestionAnswerModel)
    ) {
      throw new TypeError(
        'The answer must be an instance of class "NotEvaluatedQuestionAnswerModel"',
      );
    }

    const notEvaluatedQuestionAnswerCommand =
      new NotEvaluatedQuestionAnswerCommand(notEvaluatedQuestionAnswer);

    return await super.answer(
      activitySessionId,
      activityItemId,
      notEvaluatedQuestionAnswerCommand,
    );
  }

  prepareAnswerAndEvaluation(item) {
    const itemToEvaluate = JSON.parse(JSON.stringify(item));

    const answer = new NotEvaluatedQuestionAnswerModel({
      quizItemId: itemToEvaluate.id,
    });

    return {
      answer,
    };
  }
}
