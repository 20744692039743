/* eslint-disable eqeqeq */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import { CraftImageEvaluationModel } from 'core/models/elements/evaluations/craftImageEvaluation.model';
import { CraftImageQuestionModel } from 'core/models/elements/questions/craftImageQuestion.model';
import { CraftImageQuestionAnswerModel } from 'core/models/elements/answers/craftImageQuestionAnswer.model';
import { CraftImageAnswerCommand } from 'core/commands/elements/answers/craftImageQuestionAnswer.command';
import { EVALUATION_STATUS } from '../../../constants';

export class CraftImageQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
    this.evaluationMethod = EVALUATION_STATUS.NONE;
  }

  async answer(activitySessionId, activityItemId, craftImageAnswer) {
    if (!(craftImageAnswer instanceof CraftImageQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "CraftImageQuestionAnswerModel"',
      );
    }

    const craftImageAnswerCommand = new CraftImageAnswerCommand(
      craftImageAnswer,
    );
    return await super.answer(
      activitySessionId,
      activityItemId,
      craftImageAnswerCommand,
    );
  }

  evaluate(craftImageQuestion, craftImageAnswer) {
    if (!(craftImageQuestion instanceof CraftImageQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "CraftImageQuestionModel"',
      );
    }
    if (!(craftImageAnswer instanceof CraftImageQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "CraftImageQuestionAnswerModel"',
      );
    }

    const evaluation = new CraftImageEvaluationModel(craftImageQuestion);
    evaluation.isAnswerComplete = !!craftImageAnswer?.craftedImageUrl;

    return evaluation;
  }

  prepareAnswerAndEvaluation(item, craftedImageUrl) {
    const itemToEvaluate = JSON.parse(JSON.stringify(item));
    const __answer =
      typeof craftedImageUrl == 'object' && 'tagsList' in craftedImageUrl
        ? ''
        : craftedImageUrl;
    const answer = new CraftImageQuestionAnswerModel({
      quizItemId: itemToEvaluate.id,
      craftedImageUrl: __answer,
    });
    const evaluation = this.evaluate(item, answer);

    return {
      answer,
      evaluation,
    };
  }
}
