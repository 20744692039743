import { createSlice } from '@reduxjs/toolkit';

const navigationMapModel = {
  isEnding: false,
  navigationMap: null,
  elementsTravelled:
    JSON.parse(sessionStorage.getItem('elementsTravelled')) ?? [],
};

const navigationMapSlice = createSlice({
  name: 'navigationMap',
  initialState: navigationMapModel,
  reducers: {
    setNavigationMap: (state, action) => ({
      ...state,
      navigationMap: action.payload,
    }),
    updateEndingState: (state, action) => {
      state.isEnding = action.payload;
    },
    updateElementsTravelled: (state, action) => {
      state.elementsTravelled = [...state.elementsTravelled, action.payload];
      sessionStorage.setItem(
        'elementsTravelled',
        JSON.stringify(state.elementsTravelled),
      );
    },
  },
});

export const { setNavigationMap, updateEndingState, updateElementsTravelled } =
  navigationMapSlice.actions;
export default navigationMapSlice.reducer;
