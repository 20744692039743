import { BaseEvaluationModel } from 'core/models/elements/evaluations/baseEvaluation.abstract.model';

/**
 * @class Defines an evaluation of an question.
 * The class is abstract and should be extended.
 */
export class QuestionEvaluationModel extends BaseEvaluationModel {
  /**
   * @constructor Creates an instance of question evaluation.
   * @param {question} question is the element to be evaluated.
   */
  constructor(question) {
    super();

    if (this.constructor === QuestionEvaluationModel) {
      throw new TypeError(
        'Abstract class "QuestionEvaluationModel" cannot be instantiated directly',
      );
    }
    this.isAutoEvaluative = question.isAutoEvaluative;
    this.maxScore = question.gradingScale;
  }
}
