import { createSlice } from '@reduxjs/toolkit';

const keyboardStateModel = {
  isOpenVirtualKeyboard: false,
  inputSelected: null,
  inputValue: null,
};

export const keyboardSlice = createSlice({
  name: 'keyboard',
  initialState: keyboardStateModel,
  reducers: {
    updateKeyboardOpenState: (state) => {
      state.isOpenVirtualKeyboard = !state.isOpenVirtualKeyboard;
    },
    updateInputSelected: (state, action) => {
      if (action.payload.inputSelected) {
        state.inputSelected = action.payload.inputSelected;
        state.inputValue = action.payload.inputValue;
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateKeyboardOpenState, updateInputSelected } =
  keyboardSlice.actions;

export default keyboardSlice.reducer;
