/* eslint-disable max-classes-per-file */
import { QuestionModel } from 'core/models/elements/questions/question.abstract.model';

/**
 * @class Defines a scorm bo.
 */
export class LtiQuestionModel extends QuestionModel {
  /**
   * @constructor Creates an instance of a scorm question built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.ltiProvider = bo.lti_provider;
    this.resourceLinkId = bo.resource_link_id;
  }
}
