export const apiEnvironments = {
  LOCAL: 'https://edu-api-dev.tactileo.fr', //'https://edu-api-dev.eu.ngrok.io',
  DEV: 'https://dev-api.tactileo.fr',
  PRE: 'https://edu-api-pp.tactileo.fr',
  EDU: 'https://edu-api.tactileo.fr',
  ACY: 'https://api.tactileo.africa',
  PRO: 'https://pro-api.tactileo.com',
};

export const orchardEnvironments = {
  LOCAL: 'https://edu-dev.tactileo.fr',
  DEV: 'https://dev.tactileo.fr',
  PRE: 'https://edu-pp.tactileo.fr',
  EDU: 'https://edu.tactileo.fr',
  ACY: 'https://tactileo.africa',
  PRO: 'https://pro.tactileo.com',
};

export const getApiUrlFromEnv = (env) => apiEnvironments[env];
export const getOrchardUrlFromEnv = (env) => orchardEnvironments[env];

export const resourceAssetsEnvironments = {
  LOCAL: 'https://tacdevresourceassets.blob.core.windows.net',
  DEV: 'https://tacdevresourceassets.blob.core.windows.net',
  PRE: 'https://tacpreresourceassets.blob.core.windows.net',
  EDU: 'https://taceduresourceassets.blob.core.windows.net',
  PRO: 'https://tacproresourceassets.blob.core.windows.net',
  ACY: 'https://tacacyresourceassets.blob.core.windows.net',
};

export const getResourceAssetsUrlFromEnv = (env) =>
  resourceAssetsEnvironments[env];

export const staticAssetsEnvironments = {
  LOCAL: 'https://tacdevstatic.blob.core.windows.net',
  DEV: 'https://tacdevstatic.blob.core.windows.net',
  PRE: 'https://tacprestatic.blob.core.windows.net',
  EDU: 'https://tacedustatic.blob.core.windows.net',
  PRO: 'https://tacprostatic.blob.core.windows.net',
  ACY: 'https://tacacystatic.blob.core.windows.net',
};

export const getStaticAssetsUrlFromEnv = (env) => staticAssetsEnvironments[env];

export const SCORMViewerURL = '/scorm-files/scorm-viewer/scorm-viewer.html';
export const H5PViewerJS = '/h5p/main.bundle.js';
export const H5PViewerFrameJS = '/h5p/assets/frame.bundle.js';
export const H5PViewerCSS = '/h5p/assets/styles/h5p.css';
export const H5PViewerResPath = '/h5p/resources/';

export const logError = (error) => {
  // check if the environment is not production
  if (process.env.NODE_ENV !== 'production') {
    // using no-console eslint rule to disable console error
    /* eslint-disable-next-line no-console */
    console.error(error);
  }
};
