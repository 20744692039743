import { TagModel } from 'core/models/elements/components/tag.model';

/**
 * @class Defines a choice in the context of a multiple choices question (MCQ, Poll, ...).
 */
export class ChoiceModel extends TagModel {
  /**
   * @constructor Creates an instance of a choice built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.isCorrect = bo?.is_correct;
    this.urlImage = bo.icon;
  }
}
