import { createSlice } from '@reduxjs/toolkit';

const sessionTimeFinishedModel = {
  isSessionTimeFinished: false,
};

export const sessionTimeStateSlices = createSlice({
  name: 'sessionTimeState',
  initialState: sessionTimeFinishedModel,
  reducers: {
    setSessionTimeFinished: (state, action) => ({
      ...state,
      isSessionTimeFinished: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { setSessionTimeFinished } = sessionTimeStateSlices.actions;

export default sessionTimeStateSlices.reducer;
