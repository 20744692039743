/* eslint-disable max-classes-per-file */
import { QuestionEvaluationModel } from 'core/models/elements/evaluations/questionEvaluation.abstract.model';

/**
 * @class Defines an evaluation of a audio question
 */
export class LtiEvaluationModel extends QuestionEvaluationModel {
  /**
   * @constructor Creates an instance of audio evaluation.
   * @param {ltiQuestion} ltiQuestion is the element to be evaluated.
   */
  constructor(ltiQuestion) {
    super(ltiQuestion);
  }
}
