/* eslint-disable max-classes-per-file */
import { AnswerCommand } from 'core/commands/elements/answers/answer.abstract.command';
import { tactileoAnswersDTOTypes } from 'utils/activityItem.util';

export class FreeTextQuestionAnswerCommand extends AnswerCommand {
  constructor(freeTextQuestionAnswer) {
    super(tactileoAnswersDTOTypes.FREE_TEXT_QUESTION, freeTextQuestionAnswer);
    this.answer = freeTextQuestionAnswer.answer;
    this.type_text = freeTextQuestionAnswer.typeText;
  }
}
