import { SequencingActionModel } from '../components/sequencingAction.model';

/**
 * @class Defines a base of evaluation of a question.
 * The class is abstract and should be extended.
 */
export class BaseEvaluationModel {
  /**
   * @constructor Creates an instance of a base of evaluation.
   */
  constructor() {
    if (this.constructor === BaseEvaluationModel) {
      throw new TypeError(
        'Abstract class "BaseEvaluationModel" cannot be instantiated directly',
      );
    }

    this.isCorrect = false;
    this.scaledScore = 0;
    this.rawScore = 0;
    this.maxScore = 0;
    this.sequencingAction = new SequencingActionModel();
    this.feedbackMessages = [];
    this.isAnswerComplete = false;
  }
}
