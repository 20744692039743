import { AnswerModel } from 'core/models/elements/answers/answer.abstract.model';

import { pascalToCamel } from 'utils/common.util';

/**
 * @class Defines an scorm answer corresponding to an scorm question.
 */
export class CraftImageQuestionAnswerModel extends AnswerModel {
  /**
   * @constructor Creates an instance of an scorm answer built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.craftedImageUrl = bo.craftedImageUrl;
  }

  static instantiateFromApi(data) {
    const bo = pascalToCamel(data);
    return new CraftImageQuestionAnswerModel(bo);
  }
}
