/* eslint-disable max-classes-per-file */
import { QuestionModel } from 'core/models/elements/questions/question.abstract.model';

/**
 * @class Defines a h5p bo.
 */
export class H5PQuestionModel extends QuestionModel {
  /**
   * @constructor Creates an instance of a h5p question built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.answer = bo?.answer;
    this.h5pPackageId = bo.h5p_package_id;
    this.startPage = bo.start_page;
    this.description = bo.description;
  }
}
