/**
 * @class Defines an answer command to send a request to API.
 * The class is abstract and should be extended.
 */
export class AnswerCommand {
  /**
   * @constructor Creates an instance of an answer command built from a local model object.
   * @param {answer} answer is the model object.
   */
  constructor(type, answer) {
    if (this.constructor === AnswerCommand) {
      throw new TypeError(
        'Abstract class "AnswerCommand" cannot be instantiated directly',
      );
    }
    if (!type) {
      throw new TypeError('Answer type cannot be null');
    }

    this.$type = type;
    this.player_session_id = null;
    this.activity_item_id = answer.activityItemId;
    this.time_to_answer = answer.timeToAnswer;
    this.start_date = answer.startDate;
    this.end_date = answer.endDate;
    this.marked_for_manual_evaluation = answer.markedForManualEvaluation;
    this.raw_score_override = answer.rawScoreOverride;
    this.evaluation_comment = answer.evaluationComment;
    this.saved_result_path = answer.savedResultPath || answer.SavedResultPath;
  }
}
