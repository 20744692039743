/* eslint-disable max-classes-per-file */
import { QuestionEvaluationModel } from 'core/models/elements/evaluations/questionEvaluation.abstract.model';

/**
 * @class Defines an evaluation of a map question
 */
export class MapEvaluationModel extends QuestionEvaluationModel {
  /**
   * @constructor Creates an instance of map evaluation.
   * @param {mapQuestion} mapQuestion is the element to be evaluated.
   */
  constructor(mapQuestion) {
    super(mapQuestion);
    this.results = [];
  }
}

/**
 * @class Defines an evaluation result for a map question item
 */
export class MapEvaluationResultModel {
  /**
   * @constructor Creates an instance of map evaluation result.
   */
  constructor() {
    this.itemId = null;
    this.isCorrect = false;
    this.isNoAnswer = false;
    this.correctAnswer = '';
    this.correctItemId = null;
  }
}
