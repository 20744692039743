/* eslint-disable max-classes-per-file */
import { AnswerModel } from 'core/models/elements/answers/answer.abstract.model';
import { TagObsoleteModel } from 'core/models/elements/components/tag.model';

import { pascalToCamel } from 'utils/common.util';

/**
 * @class Defines an hole answer corresponding to an hole question.
 */
export class HoleQuestionAnswerModel extends AnswerModel {
  /**
   * @constructor Creates an instance of an hole answer built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.answers = bo.answers?.map((a) => (a ? new TagObsoleteModel(a) : null));
  }

  static instantiateFromApi(data) {
    const bo = pascalToCamel(data);
    return new HoleQuestionAnswerModel(bo);
  }
}
