/* eslint-disable max-classes-per-file */
import { QuestionModel } from 'core/models/elements/questions/question.abstract.model';
import { TagModel } from 'core/models/elements/components/tag.model';

/**
 * @class Defines an exploded text question.
 */
export class ExplodedTextQuestionModel extends QuestionModel {
  /**
   * @constructor Creates an instance of an exploded text question built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.answers = bo.answers?.map((answer) => new TagModel(answer));
  }
}
