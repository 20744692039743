import { createSlice } from '@reduxjs/toolkit';
import { AUTH_STATUS } from '../constants';

const authenticationStateModel = {
  status: AUTH_STATUS.NONE,
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState: authenticationStateModel,
  reducers: {
    setAuthStatus: (_, action) => ({
      status: action.payload.status,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { setAuthStatus } = authenticationSlice.actions;

export default authenticationSlice.reducer;
