/* eslint-disable eqeqeq */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import {
  HotspotEvaluationModel,
  HotspotEvaluationResultModel,
} from 'core/models/elements/evaluations/hotspotEvaluation.model';
import { HotspotQuestionModel } from 'core/models/elements/questions/hotspotQuestion.model';
import { HotspotQuestionAnswerModel } from 'core/models/elements/answers/hotspotQuestionAnswer.model';
import { HotspotQuestionAnswerCommand } from 'core/commands/elements/answers/hotspotQuestionAnswer.command';

export class HotspotQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
  }

  async answer(activitySessionId, activityItemId, hotspotQuestionAnswer) {
    if (!(hotspotQuestionAnswer instanceof HotspotQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "HotspotQuestionAnswerModel"',
      );
    }
    const hotspotQuestionAnswerCommand = new HotspotQuestionAnswerCommand(
      hotspotQuestionAnswer,
    );

    return await super.answer(
      activitySessionId,
      activityItemId,
      hotspotQuestionAnswerCommand,
    );
  }

  evaluate(hotspotQuestion, hotspotQuestionAnswer) {
    if (!(hotspotQuestion instanceof HotspotQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "HotspotQuestionModel"',
      );
    }
    if (!(hotspotQuestionAnswer instanceof HotspotQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "HotspotQuestionAnswerModel"',
      );
    }

    const evaluation = new HotspotEvaluationModel(hotspotQuestion);

    let expectedAnswers = hotspotQuestion.areas;
    let nbCorrectAnswers = 0;
    evaluation.answerAria = [];

    evaluation.results = hotspotQuestionAnswer?.coordinates?.map((pine) => {
      const answeredPine = JSON.parse(JSON.stringify(pine));
      const result = new HotspotEvaluationResultModel();

      if (answeredPine.x < 0 || answeredPine.y < 0) {
        result.isCorrect = false;
      } else {
        result.x = answeredPine.x;
        result.y = answeredPine.y;
        result.isCorrect = expectedAnswers.some((x) => {
          if (x.matchCoordinates(answeredPine.x, answeredPine.y)) {
            evaluation.answerAria.push(x?.id);
            return true;
          }
        });
      }

      nbCorrectAnswers += result.isCorrect ? 1 : 0;
      return result;
    });

    evaluation.isAnswerComplete =
      hotspotQuestionAnswer?.coordinates?.length == expectedAnswers.length;

    if (nbCorrectAnswers == expectedAnswers.length) {
      evaluation.isCorrect = true;
      evaluation.rawScore = hotspotQuestion.gradingScale;
      evaluation.ScaledScore = 1;
    } else {
      evaluation.isCorrect = false;
    }
    return evaluation;
  }

  prepareAnswerAndEvaluation(item, coordinates = []) {
    const itemToEvaluate = JSON.parse(JSON.stringify(item));

    const answer = new HotspotQuestionAnswerModel({
      quizItemId: itemToEvaluate.id,
      coordinates:
        'tagsList' in coordinates ? coordinates.tagsList : coordinates,
    });
    const evaluation = this.evaluate(item, answer);
    return {
      answer,
      evaluation,
    };
  }

  generateTagsArray(question, isAnswered) {
    if (!(question instanceof HotspotQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "HotspotQuestionModel"',
      );
    }

    if (!isAnswered) {
      return [];
    }

    return (question?.areas || []).map((area) => {
      const leftOrigin = area.x + area.width / 2;
      const topOrigin = area.y + area.height / 2;
      return {
        id: area.id,
        leftOrigin,
        topOrigin,
        left: null,
        right: null,
      };
    });
  }
}
