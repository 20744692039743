/* eslint-disable max-classes-per-file */
import { QuestionEvaluationModel } from 'core/models/elements/evaluations/questionEvaluation.abstract.model';

/**
 * @class Defines an evaluation of a poll question
 */
export class PollEvaluationModel extends QuestionEvaluationModel {
  /**
   * @constructor Creates an instance of poll evaluation.
   * @param {pollQuestion} pollQuestion is the element to be evaluated.
   */
  constructor(pollQuestion) {
    super(pollQuestion);
    this.results = [];
  }
}

/**
 * @class Defines an evaluation result for a poll question item
 */
export class PollEvaluationResultModel {
  /**
   * @constructor Creates an instance of poll evaluation result.
   */
  constructor() {
    this.itemId = null;
    this.isChecked = false;
  }
}
