/**
 * @class Defines a hint.
 */
export class HintModel {
  /**
   * @constructor Creates an instance of hint built from an original data from the API.
   * @param {*} bo is the basic object come from API.
   */
  constructor(bo) {
    this.title = bo.Label;
    this.content = bo.Content;
  }
}
