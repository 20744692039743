/* eslint-disable eqeqeq */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import { AudioEvaluationModel } from 'core/models/elements/evaluations/audioEvaluation.model';
import { AudioQuestionModel } from 'core/models/elements/questions/audioQuestion.model';
import { AudioQuestionAnswerModel } from 'core/models/elements/answers/audioQuestionAnswer.model';
import { AudioQuestionAnswerCommand } from 'core/commands/elements/answers/audioQuestionAnswer.command';
import { EVALUATION_STATUS } from '../../../constants';

export class AudioQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
    this.evaluationMethod = EVALUATION_STATUS.NONE;
  }

  async answer(activitySessionId, activityItemId, audioQuestionAnswer) {
    if (!(audioQuestionAnswer instanceof AudioQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "AudioQuestionAnswerModel"',
      );
    }

    const audioQuestionAnswerCommand = new AudioQuestionAnswerCommand(
      audioQuestionAnswer,
    );
    return await super.answer(
      activitySessionId,
      activityItemId,
      audioQuestionAnswerCommand,
    );
  }

  evaluate(audioQuestion, audioQuestionAnswer) {
    if (!(audioQuestion instanceof AudioQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "AudioQuestionModel"',
      );
    }
    if (!(audioQuestionAnswer instanceof AudioQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "AudioAnswerModel"',
      );
    }

    const evaluation = new AudioEvaluationModel(audioQuestion);
    evaluation.isAnswerComplete = !!audioQuestionAnswer?.audioFileUrl;

    return evaluation;
  }

  prepareAnswerAndEvaluation(item, audio) {
    const itemToEvaluate = JSON.parse(JSON.stringify(item));
    const { url: audioFileUrl } = audio || {};

    const answer = new AudioQuestionAnswerModel({
      quizItemId: itemToEvaluate.id,
      audioFileUrl,
    });
    const evaluation = this.evaluate(item, answer);

    return {
      answer,
      evaluation,
    };
  }

  generateTagsObject(question, evaluation) {
    if (!(question instanceof AudioQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "AudioQuestionModel"',
      );
    }

    const audioFileUrl = evaluation?.answer?.audioFileUrl;

    if (audioFileUrl) {
      return {
        id: audioFileUrl,
        url: audioFileUrl,
      };
    }

    return null;
  }
}
