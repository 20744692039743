/* eslint-disable max-classes-per-file */
/**
 * @class Defines a bo.
 */
export class TagModel {
  /**
   * @constructor Creates an instance of bo built from an original data from the API.
   * @param {*} bo is the basic object come from API.
   */
  constructor(bo) {
    this.id = bo?.id;
    this.text = bo?.text;
    this.minExpectedValue = bo?.min_expected_value;
    this.maxExpectedValue = bo?.max_expected_value;
    this.icon = bo?.icon;
    this.audio = bo?.audio;
    this.isAdjustableArea = bo?.is_adjustable_area;
    this.isIntrusive = bo?.is_intrusive;
    this.isCaseSensitive = bo?.is_case_sensitive;
    this.isAccentSensitive = bo?.is_accent_sensitive;
    this.answerType = bo?.answer_type;
    this.evaluationType = bo?.evaluation_type;
    this.alternativeAnswers = bo?.alternative_answers;
    this.intrusiveAnswers = bo?.intrusive_answers;
  }
}

export class TagObsoleteModel {
  constructor(bo) {
    this.id = bo?.id;
    this.text = bo?.text;
    this.minExpectedValue = bo?.minExpectedValue;
    this.maxExpectedValue = bo?.maxExpectedValue;
    this.icon = bo?.icon;
    this.audio = bo?.audio;
    this.isAdjustableArea = bo?.isAdjustableArea;
    this.isIntrusive = bo?.isIntrusive;
    this.isCaseSensitive = bo?.isCaseSensitive;
    this.isAccentSensitive = bo?.isAccentSensitive;
    this.answerType = bo?.answerType;
    this.evaluationType = bo?.evaluationType;
    this.alternativeAnswers = bo?.alternativeAnswers;
    this.intrusiveAnswers = bo?.intrusiveAnswers;
  }
}
