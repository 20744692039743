/* eslint-disable eqeqeq */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import {
  PollEvaluationModel,
  PollEvaluationResultModel,
} from 'core/models/elements/evaluations/pollEvaluation.model';
import { PollQuestionModel } from 'core/models/elements/questions/pollQuestion.model';
import { PollQuestionAnswerModel } from 'core/models/elements/answers/pollQuestionAnswer.model';
import { PollQuestionAnswerCommand } from 'core/commands/elements/answers/pollQuestionAnswer.command';
import { EVALUATION_STATUS } from '../../../constants';

export class PollQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
    this.evaluationMethod = EVALUATION_STATUS.NONE;
  }

  getExpectedAnswerAsText(choices) {
    return choices?.map((choice) => choice?.text)?.join(',');
  }

  async answer(activitySessionId, activityItemId, pollQuestionAnswer) {
    if (!(pollQuestionAnswer instanceof PollQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "PollQuestionAnswerModel"',
      );
    }

    const pollQuestionAnswerCommand = new PollQuestionAnswerCommand(
      pollQuestionAnswer,
    );
    return await super.answer(
      activitySessionId,
      activityItemId,
      pollQuestionAnswerCommand,
    );
  }

  evaluate(pollQuestion, pollQuestionAnswer) {
    if (!(pollQuestion instanceof PollQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "PollQuestionModel"',
      );
    }
    if (!(pollQuestionAnswer instanceof PollQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "PollAnswerModel"',
      );
    }

    const evaluation = new PollEvaluationModel(pollQuestion);

    evaluation.results = pollQuestion?.choices?.map((choice) => {
      const result = new PollEvaluationResultModel();
      result.itemId = choice?.id;
      result.isChecked =
        pollQuestionAnswer?.selectedChoices?.indexOf(choice?.id) > -1;

      return result;
    });

    evaluation.isCorrect = true;
    evaluation.scaledScore = 1;
    evaluation.isAnswerComplete =
      pollQuestionAnswer?.selectedChoices?.length > 0;

    return evaluation;
  }

  prepareAnswerAndEvaluation(item, selectedChoices) {
    const itemToEvaluate = JSON.parse(JSON.stringify(item));

    const selectedChoicesIds = (selectedChoices?.tagsList || [])
      .filter(({ isChecked }) => isChecked)
      .map(({ id }) => id);

    const answer = new PollQuestionAnswerModel({
      quizItemId: itemToEvaluate.id,
      selectedChoices: selectedChoicesIds,
    });
    const evaluation = this.evaluate(item, answer);

    return {
      answer,
      evaluation,
    };
  }
}
