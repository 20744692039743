import { createSlice } from '@reduxjs/toolkit';

const voiceAssistantStateModel = {
  isOpenVoiceAssistant: false,
  isActiveVoiceAssistant: false,
  voiceOptions: [],
  selectedVoice: {},
  speedVoice: {},
};

export const voiceAssistantSlice = createSlice({
  name: 'voiceAssistant',
  initialState: voiceAssistantStateModel,
  reducers: {
    updateActiveVoiceAssistantState: (state, action) => {
      state.isActiveVoiceAssistant = action.payload.isActive;
    },
    updateOpenVoiceAssistant: (state) => {
      state.isOpenVoiceAssistant = !state.isOpenVoiceAssistant;
    },
    updateVoiceOptions: (state, action) => {
      state.voiceOptions = action.payload.voiceOptions;
    },
    updateSelectedVoice: (state, action) => {
      state.selectedVoice = action.payload.selectedVoice;
    },
    updateSpeedVoice: (state, action) => {
      state.speedVoice = action.payload.speedVoice;
    },
  },
});

export const {
  updateActiveVoiceAssistantState,
  updateOpenVoiceAssistant,
  updateVoiceOptions,
  updateSelectedVoice,
  updateSpeedVoice,
} = voiceAssistantSlice.actions;

export default voiceAssistantSlice.reducer;
