/* eslint-disable max-classes-per-file */
import { QuestionEvaluationModel } from 'core/models/elements/evaluations/questionEvaluation.abstract.model';

/**
 * @class Defines an evaluation of a free text question
 */
export class FreeTextEvaluationModel extends QuestionEvaluationModel {
  /**
   * @constructor Creates an instance of free text evaluation.
   * @param {freeTextQuestion} freeTextQuestion is the element to be evaluated.
   */
  constructor(freeTextQuestion) {
    super(freeTextQuestion);
    this.results = [];
  }
}

/**
 * @class Defines an evaluation result for a free text question item
 */
export class FreeTextEvaluationResultModel {
  /**
   * @constructor Creates an instance of free text evaluation result.
   */
  constructor() {
    this.isCorrect = false;
    this.correctAnswer = '';
  }
}
