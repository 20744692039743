import { createSlice } from '@reduxjs/toolkit';
import { ACTIVITY_SESSION_STATUS } from '../constants';

const activitySessionStateModel = {
  activity: null,
  template: { id: 'tactileo', variant: 'default' },
  status: ACTIVITY_SESSION_STATUS.NONE,
  contextCollectionTitle: '',
  contextCultureName: '',
};

export const activitySessionSlice = createSlice({
  name: 'activitySession',
  initialState: activitySessionStateModel,
  reducers: {
    setActivitySession: (state, action) => ({
      ...state,
      activity: action.payload.activity,
      capsuleId: action.payload.capsuleId,
      courseSessionId: action.payload.courseSessionId,
      workingSessionId: action.payload.workingSessionId,
      contextCollectionTitle: action.payload.contextCollectionTitle,
      contextCultureName: action.payload.contextCultureName,
      resumeSession: action.payload.resumeSession,
      template: action.payload.activity.template,
      isNotTestSession: action.payload.isNotTestSession,
    }),
    setActivitySessionStatus: (state, action) => ({
      ...state,
      status: action.payload.status,
    }),
    setActivitySessionActivity: (state, action) => ({
      ...state,
      activity: action.payload.activity,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  setActivitySession,
  setActivitySessionStatus,
  setActivitySessionActivity,
} = activitySessionSlice.actions;

export default activitySessionSlice.reducer;
