import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSaving: null,
};

export const saveSlice = createSlice({
  name: 'imageModalState',
  initialState,
  reducers: {
    setIsSaving: (state, action) => ({
      ...state,
      isSaving: action.payload?.isSaving,
    }),
  },
});

export const { setIsSaving } = saveSlice.actions;
export default saveSlice.reducer;
