/* eslint-disable max-classes-per-file */
import { QuestionModel } from 'core/models/elements/questions/question.abstract.model';

/**
 * @class Defines a free text question.
 */
export class FreeTextQuestionModel extends QuestionModel {
  /**
   * @constructor Creates an instance of a free text question built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.answers = bo.answers;
    this.isAccentSensitive = bo.is_accent_sensitive;
    this.allowMathematicalFormula = bo.allow_mathematical_formula;
    this.isCaseSensitive = bo.is_case_sensitive;
    this.typeText = bo.type_text;
  }
}
