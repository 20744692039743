/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import {
  FreeTextEvaluationModel,
  FreeTextEvaluationResultModel,
} from 'core/models/elements/evaluations/freeTextEvaluation.model';
import { FreeTextQuestionModel } from 'core/models/elements/questions/freeTextQuestion.model';
import { FreeTextQuestionAnswerModel } from 'core/models/elements/answers/freeTextQuestionAnswer.model';
import { FreeTextQuestionAnswerCommand } from 'core/commands/elements/answers/freeTextQuestionAnswer.command';
import { displayMode } from 'utils/activity.util';
import { LOCALE_COMPARE_SENSITIVITY, DEFAULT_LOCALE } from 'constants/index';

const { VARIANT, BASE, CASE, ACCENT } = LOCALE_COMPARE_SENSITIVITY;

export class FreeTextQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
  }

  async answer(activitySessionId, activityItemId, freeTextQuestionAnswer) {
    if (!(freeTextQuestionAnswer instanceof FreeTextQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "FreeTextQuestionAnswerModel"',
      );
    }

    const freeTextQuestionAnswerCommand = new FreeTextQuestionAnswerCommand(
      freeTextQuestionAnswer,
    );

    return await super.answer(
      activitySessionId,
      activityItemId,
      freeTextQuestionAnswerCommand,
    );
  }

  evaluate(freeTextQuestion, freeTextQuestionAnswer, culture) {
    if (!(freeTextQuestion instanceof FreeTextQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "FreeTextQuestionModel"',
      );
    }
    if (!(freeTextQuestionAnswer instanceof FreeTextQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "FreeTextAnswerModel"',
      );
    }

    const evaluation = new FreeTextEvaluationModel(freeTextQuestion);
    const result = new FreeTextEvaluationResultModel();

    if (freeTextQuestion.answers.length > 0) {
      let isAnswerCorrect = false;

      if (freeTextQuestion.typeText === displayMode.NUMBER_TEXT) {
        isAnswerCorrect = freeTextQuestion.answers.some(
          (a) => a === freeTextQuestionAnswer.answer,
        );
      } else {
        let compareSensitivity = VARIANT;
        if (
          !freeTextQuestion.isAccentSensitive &&
          !freeTextQuestion.isCaseSensitive
        ) {
          compareSensitivity = BASE;
        } else if (
          !freeTextQuestion.isAccentSensitive &&
          freeTextQuestion.isCaseSensitive
        ) {
          compareSensitivity = CASE;
        } else if (
          freeTextQuestion.isAccentSensitive &&
          !freeTextQuestion.isCaseSensitive
        ) {
          compareSensitivity = ACCENT;
        }
        isAnswerCorrect = freeTextQuestion.answers.some(
          (a) =>
            a.localeCompare(
              freeTextQuestionAnswer.answer,
              culture || DEFAULT_LOCALE,
              {
                sensitivity: compareSensitivity,
              },
            ) === 0,
        );
      }

      evaluation.isCorrect = isAnswerCorrect;
      result.isCorrect = isAnswerCorrect;
      result.correctAnswer = freeTextQuestion.answers[0];

      if (isAnswerCorrect) {
        evaluation.scaledScore = 1;
        evaluation.rawScore = freeTextQuestion.gradingScale;
      }
      evaluation.results.push(result);
    } else {
      evaluation.isCorrect = true;
      evaluation.rawScore = freeTextQuestion.gradingScale;
      evaluation.scaledScore = 1;
    }

    evaluation.isAnswerComplete = freeTextQuestionAnswer.answer !== '';

    return evaluation;
  }

  prepareAnswerAndEvaluation(item, itemAnswer, culture) {
    const __answer =
      typeof itemAnswer == 'object' && 'tagsList' in itemAnswer
        ? ''
        : itemAnswer;

    const answer = new FreeTextQuestionAnswerModel({
      quizItemId: item.id,
      answer: __answer,
      typeText: item.typeText,
    });

    const evaluation = this.evaluate(item, answer, culture);
    return {
      answer,
      evaluation,
    };
  }
}
