import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  postDetails: null,
};

export const ltiSlice = createSlice({
  name: 'ltiState',
  initialState,
  reducers: {
    setLti: (state, action) => ({
      ...state,
      postDetails: action.payload,
    }),
  },
});

export const { setLti } = ltiSlice.actions;
export default ltiSlice.reducer;
