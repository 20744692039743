/* eslint-disable max-classes-per-file */
import { AnswerCommand } from 'core/commands/elements/answers/answer.abstract.command';
import { tactileoAnswersDTOTypes } from 'utils/activityItem.util';

export class MultipleChoicesQuestionAnswerCommand extends AnswerCommand {
  constructor(multipleChoicesQuestionAnswer) {
    super(
      tactileoAnswersDTOTypes.MULTIPLE_CHOICES_QUESTION,
      multipleChoicesQuestionAnswer,
    );
    this.selected_choices = multipleChoicesQuestionAnswer.selectedChoices;
  }
}
