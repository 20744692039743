import { AnswerCommand } from 'core/commands/elements/answers/answer.abstract.command';
import { TagCommand } from 'core/commands/elements/components/tag.command';
import { tactileoAnswersDTOTypes } from 'utils/activityItem.util';

export class MapQuestionAnswerCommand extends AnswerCommand {
  constructor(mapQuestion) {
    super(tactileoAnswersDTOTypes.MAP_QUESTION, mapQuestion);
    this.items = mapQuestion.items.map((a) => new TagCommand(a));
  }
}
