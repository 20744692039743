/* eslint-disable eqeqeq */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import { H5PEvaluationModel } from 'core/models/elements/evaluations/h5pEvaluation.model';
import { H5PQuestionModel } from 'core/models/elements/questions/h5pQuestion.model';
import { H5PQuestionAnswerModel } from 'core/models/elements/answers/h5pQuestionAnswer.model';
import { H5PQuestionAnswerCommand } from 'core/commands/elements/answers/h5pQuestionAnswer.command';
import { EVALUATION_STATUS } from '../../../constants';

export class H5PQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
    this.evaluationMethod = EVALUATION_STATUS.NONE;
  }

  async answer(activitySessionId, activityItemId, h5pQuestionAnswer) {
    if (!(h5pQuestionAnswer instanceof H5PQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "h5pQuestionAnswerModel"',
      );
    }

    const h5pQuestionAnswerCommand = new H5PQuestionAnswerCommand(
      h5pQuestionAnswer,
    );
    return await super.answer(
      activitySessionId,
      activityItemId,
      h5pQuestionAnswerCommand,
    );
  }

  evaluate(h5pQuestion, h5pQuestionAnswer) {
    if (!(h5pQuestion instanceof H5PQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "H5PQuestionModel"',
      );
    }
    if (!(h5pQuestionAnswer instanceof H5PQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "H5PAnswerModel"',
      );
    }

    const evaluation = new H5PEvaluationModel(h5pQuestion);
    const h5pAnswer = JSON.parse(h5pQuestionAnswer?.answer);

    const rawScore = +h5pAnswer?.score?.raw;
    const maxScore = +h5pAnswer?.score?.max;

    if (
      !isNaN(rawScore) &&
      !isNaN(maxScore) &&
      maxScore > 0 &&
      rawScore >= 0 &&
      rawScore <= maxScore
    ) {
      evaluation.scaledScore = rawScore / maxScore;
      evaluation.rawScore = evaluation.scaledScore * h5pQuestion?.gradingScale;
    } else {
      evaluation.rawScore = h5pQuestion?.gradingScale;
      evaluation.scaledScore = 1;
    }

    evaluation.isCorrect = true;
    evaluation.isAnswerComplete = true;

    return evaluation;
  }

  prepareAnswerAndEvaluation(item) {
    const itemToEvaluate = JSON.parse(JSON.stringify(item));

    const answer = new H5PQuestionAnswerModel({
      quizItemId: itemToEvaluate.id,
      answer: '{}',
    });
    const evaluation = this.evaluate(item, answer);

    return {
      answer,
      evaluation,
    };
  }
}
