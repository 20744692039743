/* eslint-disable eqeqeq */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import {
  SortingEvaluationModel,
  SortingEvaluationResultModel,
} from 'core/models/elements/evaluations/sortingEvaluation.model';
import { SortingQuestionModel } from 'core/models/elements/questions/sortingQuestion.model';
import { SortingQuestionAnswerModel } from 'core/models/elements/answers/sortingQuestionAnswer.model';
import { SortingQuestionAnswerCommand } from 'core/commands/elements/answers/sortingQuestionAnswer.command';
import { shuffleArray } from 'utils/common.util';
import { activityItemStates } from 'utils/activityItem.util';

export const ANSWER_KEY = 'answer-';

export class SortingQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
  }

  async answer(activitySessionId, activityItemId, sortingQuestionAnswer) {
    if (!(sortingQuestionAnswer instanceof SortingQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "SortingQuestionAnswerModel"',
      );
    }

    const sortingQuestionAnswerCommand = new SortingQuestionAnswerCommand(
      sortingQuestionAnswer,
    );
    return await super.answer(
      activitySessionId,
      activityItemId,
      sortingQuestionAnswerCommand,
    );
  }

  evaluate(sortingQuestion, sortingQuestionAnswer) {
    if (!(sortingQuestion instanceof SortingQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "SortingQuestionModel"',
      );
    }
    if (!(sortingQuestionAnswer instanceof SortingQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "SortingAnswerModel"',
      );
    }

    const evaluation = new SortingEvaluationModel(sortingQuestion);

    sortingQuestion.sortTags.forEach((answer, index) => {
      let currentAnswer = sortingQuestionAnswer?.values?.[index];
      if (typeof currentAnswer === 'string') {
        currentAnswer = sortingQuestion?.sortTags?.find(
          ({ id }) => id === currentAnswer,
        );
      }
      const result = new SortingEvaluationResultModel();
      result.itemId = currentAnswer?.id;
      result.text = currentAnswer?.text;
      result.isCorrect = answer.id === currentAnswer?.id;

      evaluation.results.push(result);
    });

    evaluation.isCorrect = evaluation.results.every((x) => x.isCorrect);
    if (evaluation.isCorrect) {
      evaluation.rawScore = sortingQuestion.gradingScale;
      evaluation.scaledScore = 1;
    } else {
      evaluation.rawScore = 0;
      evaluation.scaledScore = 0;
    }

    evaluation.isAnswerComplete = evaluation.results.every(
      (x) => !x.isNoAnswer,
    );

    return evaluation;
  }

  prepareAnswerAndEvaluation(item, tags) {
    const itemToEvaluate = JSON.parse(JSON.stringify(item));

    const tagsObject = tags
      ? tags
      : this.generateTagsObject(item, activityItemStates.NOT_ANSWERED);

    const answer = new SortingQuestionAnswerModel({
      quizItemId: itemToEvaluate.id,
      values: tagsObject.tagsList,
    });
    const evaluation = this.evaluate(item, answer);

    return {
      answer,
      evaluation,
    };
  }

  generateTagsObject(question, evaluationState) {
    if (!(question instanceof SortingQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "SortingQuestionModel"',
      );
    }

    let tagsList = [];
    const tagsObject = {
      tagsList: [],
    };
    question.sortTags.forEach((answer) => {
      tagsList = tagsList.concat(answer);
    });

    // do not shuffle if activity is already answered
    if (evaluationState === activityItemStates.NOT_ANSWERED) {
      tagsList = shuffleArray(tagsList);
    }
    tagsObject.tagsList = tagsList;

    return tagsObject;
  }
}
