/* eslint-disable eqeqeq */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import {
  GroupEvaluationModel,
  GroupEvaluationResultModel,
} from 'core/models/elements/evaluations/groupEvaluation.model';
import { GroupQuestionModel } from 'core/models/elements/questions/groupQuestion.model';
import { GroupQuestionAnswerModel } from 'core/models/elements/answers/groupQuestionAnswer.model';
import { GroupQuestionAnswerCommand } from 'core/commands/elements/answers/groupQuestionAnswer.command';
import { shuffleArray } from 'utils/common.util';
import { activityItemStates } from 'utils/activityItem.util';

export class GroupQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
  }

  async answer(activitySessionId, activityItemId, groupQuestionAnswer) {
    if (!(groupQuestionAnswer instanceof GroupQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "GroupQuestionAnswerModel"',
      );
    }

    const groupQuestionAnswerCommand = new GroupQuestionAnswerCommand(
      groupQuestionAnswer,
    );
    return await super.answer(
      activitySessionId,
      activityItemId,
      groupQuestionAnswerCommand,
    );
  }

  evaluate(groupQuestion, groupQuestionAnswer) {
    if (!(groupQuestion instanceof GroupQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "GroupQuestionModel"',
      );
    }
    if (!(groupQuestionAnswer instanceof GroupQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "GroupAnswerModel"',
      );
    }

    const evaluation = new GroupEvaluationModel(groupQuestion);

    groupQuestion.groups.forEach((group) => {
      const answerGroup = groupQuestionAnswer.groups.find(
        (g) => group.id === g.id,
      );
      group?.items?.forEach((item) => {
        const result = new GroupEvaluationResultModel(group);
        result.itemId = item.id;
        result.text = item.text;
        result.isCorrect =
          answerGroup?.items?.some((answerItem) => answerItem.id === item.id) ||
          false;
        evaluation.results.push(result);
      });
    });

    evaluation.isCorrect = evaluation.results.every((x) => x.isCorrect);
    evaluation.rawScore = groupQuestion.gradingScale;
    evaluation.scaledScore = 1;

    const totalItemsInQuestion = groupQuestion.groups.reduce(
      (sum, { items }) => sum + items.length,
      0,
    );
    const totalItemsInAnswer = groupQuestionAnswer.groups.reduce(
      (sum, { items }) => sum + items.length,
      0,
    );
    if (totalItemsInQuestion > 0) {
      const nbCorrectAnswers = evaluation.results.reduce(
        (sum, { isCorrect }) => (isCorrect ? sum + 1 : sum),
        0,
      );
      const exactScoreRatio = nbCorrectAnswers / totalItemsInQuestion;
      evaluation.rawScore = exactScoreRatio * groupQuestion.gradingScale;
      evaluation.scaledScore = exactScoreRatio;
    }

    evaluation.isAnswerComplete = totalItemsInQuestion === totalItemsInAnswer;

    return evaluation;
  }

  generateTagsObject(groupQuestion, evaluationState, keyPrefix = 'group-') {
    if (!(groupQuestion instanceof GroupQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "GroupQuestionModel"',
      );
    }

    let tagsList = [];
    const tagsObject = {
      tagsList: [],
    };
    groupQuestion.groups.forEach((group) => {
      tagsList = tagsList.concat(group?.items);
      tagsObject[keyPrefix + group.id] = [];
      tagsObject[keyPrefix + group.id + '-modal'] = [];
    });

    // do not shuffle if activity is already answered
    if (evaluationState === activityItemStates.NOT_ANSWERED) {
      tagsList = shuffleArray(tagsList);
    }
    tagsObject.tagsList = tagsList;

    return tagsObject;
  }

  prepareAnswerAndEvaluation(item, tags = {}, keyPrefix = 'group-') {
    const tagsToEvaluate = JSON.parse(JSON.stringify(tags));
    const itemToEvaluate = JSON.parse(JSON.stringify(item));
    itemToEvaluate.groups.forEach((group) => {
      group.items = tagsToEvaluate[keyPrefix + group.id] || [];
    });
    const answer = new GroupQuestionAnswerModel({
      quizItemId: itemToEvaluate.id,
      groups: itemToEvaluate.groups,
    });
    const evaluation = this.evaluate(item, answer);
    return {
      answer,
      evaluation,
    };
  }
}
