import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import axios from 'axios';

import { LtiEvaluationModel } from 'core/models/elements/evaluations/ltiEvaluation.model';
import { LtiQuestionModel } from 'core/models/elements/questions/ltiQuestion.model';
import { LtiQuestionAnswerModel } from 'core/models/elements/answers/ltiQuestionAnswer.model';
import { LtiQuestionAnswerCommand } from 'core/commands/elements/answers/ltiQuestionAnswer.command';

export class LtiQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
  }

  async answer(activitySessionId, activityItemId, ltiQuestionAnswer) {
    if (!(ltiQuestionAnswer instanceof LtiQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "LtiQuestionAnswerModel"',
      );
    }
    const ltiQuestionAnswerCommand = new LtiQuestionAnswerCommand(
      ltiQuestionAnswer,
    );
    return await super.answer(
      activitySessionId,
      activityItemId,
      ltiQuestionAnswerCommand,
    );
  }

  evaluate(ltiQuestion, ltiQuestionAnswer, ltiQuestionObject) {
    if (!(ltiQuestion instanceof LtiQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "LtiQuestionModel"',
      );
    }
    if (!(ltiQuestionAnswer instanceof LtiQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "LtiQuestionAnswerModel"',
      );
    }

    const evaluation = new LtiEvaluationModel(ltiQuestion);
    if (ltiQuestionObject?.ScaledScore) {
      evaluation.rawScore = ltiQuestionObject?.ScaledScore
        ? ltiQuestionObject?.ScaledScore
        : 0;
      evaluation.maxScore = ltiQuestionObject?.ScaledScore ? 1 : 0;
      evaluation.scaledScore = 1;
      evaluation.isCorrect =
        ltiQuestionObject?.ScaledScore >= 0.5 ? true : false;
    }

    evaluation.isAnswerComplete =
      !!ltiQuestionAnswer?.lti_results ||
      !!ltiQuestionAnswer?.ltiResults ||
      !!ltiQuestionAnswer?.savedResultPath ||
      !!ltiQuestionAnswer?.scaledScore ||
      ltiQuestionAnswer?.scaledScore >= 0;

    return evaluation;
  }

  prepareAnswerAndEvaluation(item, ltiResults) {
    const itemToEvaluate = JSON.parse(JSON.stringify(item));
    let answer;
    let evaluation;

    try {
      const answerTmp = JSON.parse(ltiResults);
      const rev = new Map(
        Object.entries(Object.assign({}, answerTmp)).reverse(),
      );
      // It is important to have the __type in the first position in the object
      const ltiObject = Object.fromEntries(rev);
      answer = LtiQuestionAnswerModel.instantiateFromApi({
        quizItemId: itemToEvaluate.id,
        ...ltiObject,
      });
      console.log('ltiQuestion.service answer', answer);
      evaluation = this.evaluate(item, answer, ltiObject);
    } catch (e) {
      // Case Lti 1.0 where LtiResults is an encrypted string
      answer = LtiQuestionAnswerModel.instantiateFromApi({
        quizItemId: itemToEvaluate.id,
        lti_results: ltiResults,
      });
      console.log('1.0 ltiQuestion.service answer', answer);
      evaluation = this.evaluate(item, answer);
    }

    return {
      answer,
      evaluation,
    };
  }

  async launchLti(activitySessionId, questionId, messageType) {
    return await axios.post(
      `${this.apiUrl}/api/lti/launch?activitySessionId=${activitySessionId}&questionId=${questionId}&messageType=${messageType}`,
      {},
      {
        headers: this.authorization,
      },
    );
  }
}
