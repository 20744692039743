import { AnswerModel } from 'core/models/elements/answers/answer.abstract.model';
import { TagObsoleteModel } from 'core/models/elements/components/tag.model';

import { pascalToCamel } from 'utils/common.util';

/**
 * @class Defines an map answer corresponding to an map question.
 */
export class MapQuestionAnswerModel extends AnswerModel {
  /**
   * @constructor Creates an instance of an map answer built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.items = bo.items?.map((a) => new TagObsoleteModel(a));
  }

  static instantiateFromApi(data) {
    const bo = pascalToCamel(data);
    return new MapQuestionAnswerModel(bo);
  }
}
