import { createSlice } from '@reduxjs/toolkit';

const header = {
  isHeaderOpen: false,
};

export const headerSlice = createSlice({
  name: 'header',
  initialState: header,
  reducers: {
    updateHeaderState: (state, action) => {
      state.isHeaderOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateHeaderState } = headerSlice.actions;

export default headerSlice.reducer;
