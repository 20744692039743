/* eslint-disable max-classes-per-file */
import { QuestionEvaluationModel } from 'core/models/elements/evaluations/questionEvaluation.abstract.model';

/**
 * @class Defines an evaluation of a multiple choices question
 */
export class MultipleChoicesEvaluationModel extends QuestionEvaluationModel {
  /**
   * @constructor Creates an instance of multiple choices evaluation.
   * @param {multipleChoicesQuestion} multipleChoicesQuestion is the element to be evaluated.
   */
  constructor(multipleChoicesQuestion) {
    super(multipleChoicesQuestion);
    this.results = [];
  }
}

/**
 * @class Defines an evaluation result for a multiple choices question item
 */
export class MultipleChoicesEvaluationResultModel {
  /**
   * @constructor Creates an instance of multiple choices evaluation result.
   */
  constructor() {
    this.itemId = null;
    this.isCorrect = false;
    this.isChecked = false;
  }
}
