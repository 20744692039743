/* eslint-disable max-classes-per-file */
import { QuestionEvaluationModel } from 'core/models/elements/evaluations/questionEvaluation.abstract.model';

/**
 * @class Defines an evaluation of a scorm question
 */
export class CraftImageEvaluationModel extends QuestionEvaluationModel {
  /**
   * @constructor Creates an instance of craft image evaluation.
   * @param {craftImageQuestion} craftImageQuestion is the element to be evaluated.
   */
  constructor(craftImageQuestion) {
    super(craftImageQuestion);
  }
}
