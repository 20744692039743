/* eslint-disable max-classes-per-file */
import { QuestionModel } from 'core/models/elements/questions/question.abstract.model';
import { TagModel } from 'core/models/elements/components/tag.model';

/**
 * @class Defines an associate question.
 */
export class AssociateQuestionModel extends QuestionModel {
  /**
   * @constructor Creates an instance of an associate question built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.items = bo.items.map((item) => new AssociateItemModel(item));
    this.initialItems = bo.initial_items
      ? bo.initial_items.map((item) => new AssociateItemModel(item))
      : undefined;
    this.displayMode = bo.display_mode;
  }
}

export class AssociateItemModel {
  /**
   * @constructor Creates an instance of an associate item built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    this.leftTag = new TagModel(bo.l_tag);
    this.rightTag = new TagModel(bo.r_tag);
  }
}
