import { createSlice } from '@reduxjs/toolkit';

const userModel = {
  firstName: null,
  lastName: null,
  isTeacher: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: userModel,
  reducers: {
    setCurrentUser: (_, action) => ({
      firstName: action?.payload?.firstName,
      lastName: action?.payload?.lastName,
      isTeacher: action?.payload?.isTeacher,
      IdUser: action?.payload?.IdUser,
    }),
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentUser } = userSlice.actions;

export default userSlice.reducer;
