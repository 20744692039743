/* eslint-disable max-classes-per-file */
import { QuestionModel } from 'core/models/elements/questions/question.abstract.model';

/**
 * @class Defines a audio question.
 */
export class AudioQuestionModel extends QuestionModel {
  /**
   * @constructor Creates an instance of a audio question built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
  }
}
