class ConditionModel {
  constructor(bo) {
    this.status = bo.status;
    this.choiceId = bo.choice_id;
    this.tagId = bo.tag_id;
  }
}

class ActionModel {
  constructor(bo) {
    this.rule = bo.rule;
    this.nextActivityItemId = bo.next_activity_item_id;
  }
}

/**
 * @class Defines a triggers.
 */
export class TriggersModel {
  /**
   * @constructor Creates an instance of triggers built from an original data from the API.
   * @param {*} bo is the basic object come from API.
   */
  constructor(bo) {
    this.title = bo.title;
    this.choiceConditions = bo?.condition?.choice_conditions?.map(
      (x) => new ConditionModel(x),
    );
    this.tagConditions = bo?.condition?.tag_conditions?.map(
      (x) => new ConditionModel(x),
    );
    this.action = new ActionModel(bo.action);
    this.feedback = bo.feedback;
    this.ignoreNextTriggers = bo.ignore_next_triggers;
  }
}
