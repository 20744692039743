/* eslint-disable max-classes-per-file */
import { AnswerModel } from 'core/models/elements/answers/answer.abstract.model';

import { pascalToCamel } from 'utils/common.util';

/**
 * @class Defines a multiple choices answer corresponding to an hole question.
 */
export class MultipleChoicesQuestionAnswerModel extends AnswerModel {
  /**
   * @constructor Creates an instance of a multiple choices answer built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.selectedChoices = bo.selectedChoices;
  }

  static instantiateFromApi(data) {
    const bo = pascalToCamel(data);
    return new MultipleChoicesQuestionAnswerModel(bo);
  }
}
