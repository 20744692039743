/* eslint-disable eqeqeq */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import { ScormEvaluationModel } from 'core/models/elements/evaluations/scormEvaluation.model';
import { ScormQuestionModel } from 'core/models/elements/questions/scormQuestion.model';
import { ScormQuestionAnswerModel } from 'core/models/elements/answers/scormQuestionAnswer.model';
import { ScormQuestionAnswerCommand } from 'core/commands/elements/answers/scormQuestionAnswer.command';
import { EVALUATION_STATUS } from '../../../constants';

export class ScormQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
    this.evaluationMethod = EVALUATION_STATUS.NONE;
  }

  async answer(activitySessionId, activityItemId, scormQuestionAnswer) {
    if (!(scormQuestionAnswer instanceof ScormQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "ScormQuestionAnswerModel"',
      );
    }

    const scormQuestionAnswerCommand = new ScormQuestionAnswerCommand(
      scormQuestionAnswer,
    );
    return await super.answer(
      activitySessionId,
      activityItemId,
      scormQuestionAnswerCommand,
    );
  }

  evaluate(scormQuestion, scormQuestionAnswer) {
    if (!(scormQuestion instanceof ScormQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "ScormQuestionModel"',
      );
    }
    if (!(scormQuestionAnswer instanceof ScormQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "ScormAnswerModel"',
      );
    }

    const evaluation = new ScormEvaluationModel(scormQuestion);
    const scormAnswer = JSON.parse(scormQuestionAnswer?.answer);

    const rawScore = +scormAnswer?.score?.raw;
    const maxScore = +scormAnswer?.score?.max;

    if (
      !isNaN(rawScore) &&
      !isNaN(maxScore) &&
      maxScore > 0 &&
      rawScore >= 0 &&
      rawScore <= maxScore
    ) {
      evaluation.scaledScore = rawScore / maxScore;
      evaluation.rawScore =
        evaluation.scaledScore * scormQuestion?.gradingScale;
    } else {
      evaluation.rawScore = scormQuestion?.gradingScale;
      evaluation.scaledScore = 1;
    }

    evaluation.isCorrect = true;
    evaluation.isAnswerComplete = true;

    return evaluation;
  }

  prepareAnswerAndEvaluation(item) {
    const itemToEvaluate = JSON.parse(JSON.stringify(item));

    const answer = new ScormQuestionAnswerModel({
      quizItemId: itemToEvaluate.id,
      answer: '{}',
    });
    const evaluation = this.evaluate(item, answer);

    return {
      answer,
      evaluation,
    };
  }
}
