import { ActivityItemModel } from 'core/models/elements/activityItem.abstract.model';

/**
 * @class Defines a question.
 * The class is abstract and should be extended.
 */
export class QuestionModel extends ActivityItemModel {
  /**
   * @constructor Creates an instance of a question built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);

    if (this.constructor === QuestionModel) {
      throw new TypeError(
        'Abstract class "QuestionModel" cannot be instantiated directly',
      );
    }

    this.body = bo.body;
    this.correctMessage = bo.correct_message;
    this.incorrectMessage = bo.incorrect_message;
    this.gradingScale = bo.grading_scale;
    this.isModalInstruction = bo.is_modal_instruction;
    this.showResultMode = bo.show_result_mode;
  }
}
