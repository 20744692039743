export class TagCommand {
  constructor(tag) {
    this.id = tag.id;
    this.text = tag.text;
    this.min_expected_value = tag.minExpectedValue;
    this.max_expected_value = tag.maxExpectedValue;
    this.icon = tag.icon;
    this.audio = tag.audio;
    this.is_adjustable_area = tag.isAdjustableArea;
    this.is_intrusive = tag.isIntrusive;
    this.is_case_sensitive = tag.isCaseSensitive;
    this.is_accent_sensitive = tag.isAccentSensitive;
    this.answer_type = tag.answerType;
    this.evaluation_type = tag.evaluationType;
    this.alternative_answers = tag.alternativeAnswers;
    this.intrusive_answers = tag.intrusiveAnswers;
  }
}
