/* eslint-disable max-classes-per-file */
import { AnswerCommand } from 'core/commands/elements/answers/answer.abstract.command';
import { TagCommand } from 'core/commands/elements/components/tag.command';
import { tactileoAnswersDTOTypes } from 'utils/activityItem.util';

export class ExplodedTextQuestionAnswerCommand extends AnswerCommand {
  constructor(explodedTextQuestionAnswer) {
    super(
      tactileoAnswersDTOTypes.EXPLODED_TEXT_QUESTION,
      explodedTextQuestionAnswer,
    );
    this.answers = explodedTextQuestionAnswer.answers.map(
      (a) => new TagCommand(a),
    );
  }
}
