/* eslint-disable max-classes-per-file */
import { QuestionEvaluationModel } from 'core/models/elements/evaluations/questionEvaluation.abstract.model';

/**
 * @class Defines an evaluation of an hole question
 */
export class HoleEvaluationModel extends QuestionEvaluationModel {
  /**
   * @constructor Creates an instance of hole evaluation.
   * @param {holeQuestion} holeQuestion is the element to be evaluated.
   */
  constructor(holeQuestion) {
    super(holeQuestion);
    this.results = [];
  }
}

/**
 * @class Defines an evaluation result for a hole question item
 */
export class HoleEvaluationResultModel {
  /**
   * @constructor Creates an instance of hole evaluation result.
   */
  constructor() {
    this.itemId = null;
    this.isCorrect = false;
    this.isNoAnswer = false;
    this.correctAnswer = null;
  }
}
