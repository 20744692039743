/* eslint-disable class-methods-use-this */
/* eslint-disable no-return-await */
import axios from 'axios';

import { StatusCodes } from 'http-status-codes';
import { BaseService } from 'core/services/base.abstract.service';
import { ActivityModel } from 'core/models/activity.model';
import { ActivityItemModel } from 'core/models/elements/activityItem.abstract.model';
import { activityItemEvaluationMethod } from 'utils/activityItem.util';
import { activityDisplayCorrectionMode } from 'utils/activity.util';

export class ActivityItemService extends BaseService {
  constructor(env) {
    super(env);
    if (this.constructor === ActivityItemService) {
      throw new TypeError(
        'Abstract class "ActivityItemService" cannot be instantiated directly',
      );
    }
  }

  /**
   * Save the answer of a question
   * @param {*} answer represent an activity item
   */
  async answer(activitySessionId, activityItemId, answerCommand) {
    var key = 'activity-player-sessionid-' + activitySessionId;
    answerCommand.player_session_id = sessionStorage.getItem(key);
    return await axios
      .put(
        `${this.apiUrl}/api/activitysessions/${activitySessionId}/items/${activityItemId}/answer`,
        JSON.stringify(answerCommand),
        {
          headers: this.authorization,
        },
      )
      .then(async (res) => {
        if (
          [StatusCodes.CREATED, StatusCodes.NO_CONTENT].includes(res.status)
        ) {
          return true;
        }
        const ex = await res?.data;
        throw new Error(ex.error);
      });
  }

  /**
   * Evaluates the answer to a question
   * @param {*} item
   * @param {*} answer
   */
  evaluate() {
    throw new Error('You must implement this function');
  }

  /**
   *
   * @param {*} item
   * @param {*} activity
   * @param {*} isSessionFinished
   * @returns
   */
  getItemResultMode(item, activity) {
    if (!(item instanceof ActivityItemModel)) {
      throw new TypeError(
        'The item must be an instance of class "ActivityItemModel"',
      );
    }
    if (!(activity instanceof ActivityModel)) {
      throw new TypeError(
        'The activity must be an instance of class "ActivityModel"',
      );
    }
    if (item.evaluationMethod !== activityItemEvaluationMethod.AUTO) {
      return activityDisplayCorrectionMode.DISPLAY_RESULT;
    }
    return item.showResultMode !== null
      ? item.showResultMode
      : activity.itemsDisplayCorrectionMode;
  }
}
