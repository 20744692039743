/* eslint-disable eqeqeq */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import {
  MultipleChoicesEvaluationModel,
  MultipleChoicesEvaluationResultModel,
} from 'core/models/elements/evaluations/multipleChoicesEvaluation.model';
import { MultipleChoicesQuestionModel } from 'core/models/elements/questions/multipleChoicesQuestion.model';
import { MultipleChoicesQuestionAnswerModel } from 'core/models/elements/answers/multipleChoicesQuestionAnswer.model';
import { MultipleChoicesQuestionAnswerCommand } from 'core/commands/elements/answers/multipleChoicesQuestionAnswer.command';

export class MultipleChoicesQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
  }

  async answer(
    activitySessionId,
    activityItemId,
    multipleChoicesQuestionAnswer,
  ) {
    if (
      !(
        multipleChoicesQuestionAnswer instanceof
        MultipleChoicesQuestionAnswerModel
      )
    ) {
      throw new TypeError(
        'The answer must be an instance of class "MultipleChoicesQuestionAnswerModel"',
      );
    }

    const multipleChoicesQuestionAnswerCommand =
      new MultipleChoicesQuestionAnswerCommand(multipleChoicesQuestionAnswer);
    return await super.answer(
      activitySessionId,
      activityItemId,
      multipleChoicesQuestionAnswerCommand,
    );
  }

  evaluate(multipleChoicesQuestion, multipleChoicesQuestionAnswer) {
    if (!(multipleChoicesQuestion instanceof MultipleChoicesQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "MultipleChoicesQuestionModel"',
      );
    }
    if (
      !(
        multipleChoicesQuestionAnswer instanceof
        MultipleChoicesQuestionAnswerModel
      )
    ) {
      throw new TypeError(
        'The answer must be an instance of class "MultipleChoicesAnswerModel"',
      );
    }

    const evaluation = new MultipleChoicesEvaluationModel(
      multipleChoicesQuestion,
    );

    evaluation.results = multipleChoicesQuestion.choices.map((choice) => {
      const result = new MultipleChoicesEvaluationResultModel();
      result.itemId = choice?.id;
      result.isCorrect = choice.isCorrect;
      result.isChecked =
        multipleChoicesQuestionAnswer.selectedChoices?.includes(choice?.id);
      return result;
    });

    evaluation.isCorrect = evaluation.results.every(
      (x) => x.isCorrect === x.isChecked,
    );
    if (evaluation.isCorrect) {
      evaluation.rawScore = multipleChoicesQuestion.gradingScale;
      evaluation.scaledScore = 1;
    } else {
      evaluation.rawScore = 0;
      evaluation.scaledScore = 0;
    }

    evaluation.isAnswerComplete =
      multipleChoicesQuestionAnswer.selectedChoices?.length > 0;

    return evaluation;
  }

  prepareAnswerAndEvaluation(item, selectedChoices) {
    const itemToEvaluate = JSON.parse(JSON.stringify(item));
    const selectedChoicesIds = (selectedChoices?.tagsList || [])
      .filter(({ isChecked }) => isChecked)
      .map(({ id }) => id);

    const answer = new MultipleChoicesQuestionAnswerModel({
      quizItemId: itemToEvaluate.id,
      selectedChoices: selectedChoicesIds,
    });
    const evaluation = this.evaluate(item, answer);

    return {
      answer,
      evaluation,
    };
  }
}
