/* eslint-disable eqeqeq */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import {
  ExplodedTextEvaluationModel,
  ExplodedTextEvaluationResultModel,
} from 'core/models/elements/evaluations/explodedTextEvaluation.model';
import { ExplodedTextQuestionModel } from 'core/models/elements/questions/explodedTextQuestion.model';
import { ExplodedTextQuestionAnswerModel } from 'core/models/elements/answers/explodedTextQuestionAnswer.model';
import { ExplodedTextQuestionAnswerCommand } from 'core/commands/elements/answers/explodedTextQuestionAnswer.command';
import { shuffleArray } from 'utils/common.util';
import { activityItemStates } from 'utils/activityItem.util';

export const ANSWER_KEY = 'answer-';

export class ExplodedTextQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
  }

  async answer(activitySessionId, activityItemId, explodedTextQuestionAnswer) {
    if (
      !(explodedTextQuestionAnswer instanceof ExplodedTextQuestionAnswerModel)
    ) {
      throw new TypeError(
        'The answer must be an instance of class "ExplodedTextQuestionAnswerModel"',
      );
    }

    return await super.answer(
      activitySessionId,
      activityItemId,
      new ExplodedTextQuestionAnswerCommand(explodedTextQuestionAnswer),
    );
  }

  evaluate(explodedTextQuestion, explodedTextQuestionAnswer) {
    if (!(explodedTextQuestion instanceof ExplodedTextQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "ExplodedTextQuestionModel"',
      );
    }
    if (
      !(explodedTextQuestionAnswer instanceof ExplodedTextQuestionAnswerModel)
    ) {
      throw new TypeError(
        'The answer must be an instance of class "ExplodedTextAnswerModel"',
      );
    }

    const evaluation = new ExplodedTextEvaluationModel(explodedTextQuestion);

    explodedTextQuestion.answers.forEach((answer, index) => {
      const result = new ExplodedTextEvaluationResultModel();
      const userAnswer = explodedTextQuestionAnswer?.answers[index];

      result.itemId = answer?.id;
      result.text = userAnswer?.text;
      result.isCorrect = answer?.id === userAnswer?.id;

      evaluation.results.push(result);
    });

    evaluation.isCorrect = evaluation.results.every((x) => x.isCorrect);
    evaluation.rawScore = explodedTextQuestion.gradingScale;
    evaluation.scaledScore = 1;

    const totalItemsInQuestion = explodedTextQuestion.answers.length;
    const totalItemsInAnswer = explodedTextQuestionAnswer.answers.filter(
      (item) => item?.id,
    ).length;

    if (totalItemsInQuestion > 0) {
      const nbCorrectAnswers = evaluation.results.reduce(
        (sum, { isCorrect }) => (isCorrect ? sum + 1 : sum),
        0,
      );
      const exactScoreRatio = nbCorrectAnswers / totalItemsInQuestion;
      evaluation.rawScore = exactScoreRatio * explodedTextQuestion.gradingScale;
      evaluation.scaledScore = exactScoreRatio;
    }

    evaluation.isAnswerComplete = totalItemsInQuestion === totalItemsInAnswer;

    return evaluation;
  }

  prepareAnswerAndEvaluation(item, tags = {}) {
    const tagsToEvaluate = JSON.parse(JSON.stringify(tags));
    const itemToEvaluate = JSON.parse(JSON.stringify(item));

    const answersToEvaluate = Object.keys(tagsToEvaluate).reduce((acc, key) => {
      if (key === 'tagsList') return acc;

      return [...acc, tagsToEvaluate?.[key]?.[0]];
    }, []);

    const answer = new ExplodedTextQuestionAnswerModel({
      quizItemId: itemToEvaluate.id,
      answers: answersToEvaluate,
    });

    const evaluation = this.evaluate(item, answer);

    return {
      answer,
      evaluation,
    };
  }

  generateTagsObject(question, evaluationState, isMobile = false) {
    if (!(question instanceof ExplodedTextQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "ExplodedTextQuestionModel"',
      );
    }

    let tagsList = [];
    const tagsObject = {
      tagsList: [],
    };
    question.answers.forEach((answer, index) => {
      let newAnswer = answer;
      if (isMobile) {
        newAnswer = {
          ...answer,
          resultAnswerId: null,
          active: false,
          selected: false,
          disabled: false,
        };
      }
      tagsList = tagsList.concat(newAnswer);
      tagsObject[ANSWER_KEY + index] = [];
    });

    // do not shuffle if activity is already answered
    if (evaluationState === activityItemStates.NOT_ANSWERED) {
      tagsList = shuffleArray(tagsList);
    }
    tagsObject.tagsList = tagsList;

    return tagsObject;
  }
}
