/* eslint-disable max-classes-per-file */
import { QuestionEvaluationModel } from 'core/models/elements/evaluations/questionEvaluation.abstract.model';

/**
 * @class Defines an evaluation of a scorm question
 */
export class H5PEvaluationModel extends QuestionEvaluationModel {
  /**
   * @constructor Creates an instance of scorm evaluation.
   * @param {h5pQuestion} h5pQuestion is the element to be evaluated.
   */
  constructor(h5pQuestion) {
    super(h5pQuestion);
  }
}
