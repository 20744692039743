/* eslint-disable max-classes-per-file */
import { QuestionModel } from 'core/models/elements/questions/question.abstract.model';
import { ChoiceModel } from '../components/choice.model';

/**
 * @class Defines a poll bo.
 */
export class PollQuestionModel extends QuestionModel {
  /**
   * @constructor Creates an instance of a poll question built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.choices = bo.choices
      ? bo.choices.map((choice) => new ChoiceModel(choice))
      : undefined;
    this.displayMode = bo.display_mode;
    this.constrainNumberChoices = bo.constrain_number_choices;
    this.minChoices = bo.minimum_choices;
    this.maxChoices = bo.maximum_choices;
    this.subDisplayMode = bo.sub_display_mode;
  }
}
