import { createSlice } from '@reduxjs/toolkit';

const audioModel = {
  id: '',
  url: '',
  isPause: true,
  isOpen: false,
};

export const audioSlice = createSlice({
  name: 'audio',
  initialState: audioModel,
  reducers: {
    setAudio: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    openAudio: (_, action) => {
      return {
        id: action.payload.id,
        url: action.payload.url,
        isPause: false,
        isOpen: true,
      };
    },
    pauseAudio: (state) => {
      return { ...state, isPause: true };
    },
    closeAudio: () => {
      return { ...audioModel };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAudio, openAudio, pauseAudio, closeAudio } =
  audioSlice.actions;

export default audioSlice.reducer;
