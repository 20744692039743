/**
 * @class Defines a sequencing action.
 */
export class SequencingActionModel {
  /**
   * Create an instance of sequencing action.
   */
  constructor() {
    this.rule = sequencingActionType.NO_ACTION;
    this.nextActivityItemId = null;
  }
}

/**
 * The enum of sequening action type.
 */
export const sequencingActionType = {
  NO_ACTION: 0,
  GO_TO_NEXT_ITEM: 1,
  FINISH_SUCCESS: 2,
  FINISH_FAILED: 3,
  GO_TO_ITEM: 4,
  FINISH: 5,
};
