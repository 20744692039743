/**
 * @class Defines an activity template.
 */
export class ActivityTemplateModel {
  /**
   * @constructor Creates an instance of an activity template built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    this.id = bo.TemplateId;
    this.variant = bo.VariantId;
  }
}
