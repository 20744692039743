/* eslint-disable max-classes-per-file */
import { AnswerCommand } from 'core/commands/elements/answers/answer.abstract.command';
import { TagCommand } from 'core/commands/elements/components/tag.command';
import { tactileoAnswersDTOTypes } from 'utils/activityItem.util';

export class GroupQuestionAnswerCommand extends AnswerCommand {
  constructor(groupQuestionAnswer) {
    super(tactileoAnswersDTOTypes.GROUP_QUESTION, groupQuestionAnswer);
    this.groups = groupQuestionAnswer.groups.map(
      (g) => new GroupAnswerCommand(g),
    );
  }
}

export class GroupAnswerCommand {
  constructor(group) {
    this.id = group.id;
    this.text = group.text;
    this.url_image = group.urlImage;
    this.css_class = group.cssClass;
    this.items = group.items.map((i) => new TagCommand(i));
  }
}
