/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import {
  AssociateEvaluationModel,
  AssociateEvaluationResultModel,
} from 'core/models/elements/evaluations/associateEvaluation.model';
import { AssociateQuestionModel } from 'core/models/elements/questions/associateQuestion.model';
import { AssociateQuestionAnswerModel } from 'core/models/elements/answers/associateQuestionAnswer.model';
import { AssociateQuestionAnswerCommand } from 'core/commands/elements/answers/associateQuestionAnswer.command';
import { shuffleArray } from 'utils/common.util';
import { displayMode } from 'utils/activity.util';
const { PAIRS } = displayMode;

export const EMPTY_ID = '00000000-0000-0000-0000-000000000000';

export class AssociateQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
  }

  async answer(activitySessionId, activityItemId, associateQuestionAnswer) {
    if (!(associateQuestionAnswer instanceof AssociateQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "AssociateQuestionAnswerModel"',
      );
    }

    const associateQuestionAnswerCommand = new AssociateQuestionAnswerCommand(
      associateQuestionAnswer,
    );

    return await super.answer(
      activitySessionId,
      activityItemId,
      associateQuestionAnswerCommand,
    );
  }

  evaluate(associateQuestion, associateQuestionAnswer, typeExercise) {
    if (!(associateQuestion instanceof AssociateQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "AssociateQuestionModel"',
      );
    }
    if (!(associateQuestionAnswer instanceof AssociateQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "AssociateAnswerModel"',
      );
    }

    const evaluation = new AssociateEvaluationModel(associateQuestion);
    const itemsLength = associateQuestion?.items?.length || 0;
    const isNoEmptyId = associateQuestionAnswer?.rIds?.every(
      (rId) => rId !== EMPTY_ID,
    );
    let isAnswerCorrect = itemsLength > 0;
    let nbCorrectAnswers = 0;

    associateQuestion?.items?.map((item, index) => {
      const expectedId = item?.rightTag?.id;
      const result = new AssociateEvaluationResultModel();

      result.itemId = item?.leftTag?.id;
      result.associateId = associateQuestionAnswer?.rIds?.[index];
      result.correctItemId = expectedId;
      if (typeExercise === PAIRS) {
        result.isCorrect = isNoEmptyId;
      } else {
        result.isCorrect =
          associateQuestionAnswer?.rIds?.[index] === item.rightTag.id;
      }
      isAnswerCorrect &&= result.isCorrect;
      nbCorrectAnswers += result.isCorrect ? 1 : 0;

      evaluation.results.push(result);
    });

    if (itemsLength > 0) {
      evaluation.scaledScore = nbCorrectAnswers / (itemsLength || 1);
      evaluation.rawScore =
        evaluation.scaledScore * associateQuestion.gradingScale;
    } else {
      evaluation.rawScore = evaluation.scaledScore;
      evaluation.scaledScore = 1;
    }

    evaluation.isCorrect = isAnswerCorrect;
    evaluation.isAnswerComplete = isNoEmptyId;

    return evaluation;
  }

  generateInitRIds(item, receivedRIds) {
    return receivedRIds?.length > 0
      ? receivedRIds
      : item?.items?.map(() => EMPTY_ID);
  }

  prepareAnswerAndEvaluation(item, rIds, typeExercise) {
    const __answer =
      typeof rIds == 'object' && 'tagsList' in rIds ? rIds.tagsList : rIds;
    const answer = new AssociateQuestionAnswerModel({
      quizItemId: item.id,
      rIds: __answer,
    });

    const evaluation = this.evaluate(item, answer, typeExercise);
    return {
      answer,
      evaluation,
    };
  }

  transformItem(index, item, rId, rIds, isAnswered) {
    const isCorrect = rIds?.indexOf(rId) === index;

    return {
      index,
      rId,
      id:
        item?.id === '00000000-0000-0000-0000-000000000000'
          ? `${index}${rId}`
          : item?.id,
      label: item?.text,
      imgUrl: item?.icon,
      disabled: isAnswered,
      isSelected: isCorrect,
      audioUrl: item?.audio,
      isCorrect,
    };
  }

  generateAssociateItems(associateQuestion, rIds, isAnswered, isMobile) {
    if (!(associateQuestion instanceof AssociateQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "AssociateQuestionModel"',
      );
    }

    const leftTags = [];
    const rightTags = [];
    const isGenerated = true;
    associateQuestion?.items?.forEach((item, index) => {
      const rightTag = item?.rightTag;
      leftTags.push(
        this.transformItem(
          index,
          item?.leftTag,
          rightTag?.id,
          rIds,
          isAnswered,
        ),
      );
      rightTags.push(
        this.transformItem(index, rightTag, rightTag?.id, rIds, isAnswered),
      );
    });

    if (associateQuestion?.displayMode === displayMode.ASSOCIATE && !isMobile) {
      // do not shuffle if activity is already answered
      return isAnswered
        ? {
            leftTags,
            rightTags,
            isGenerated,
          }
        : {
            leftTags: shuffleArray(leftTags),
            rightTags: shuffleArray(rightTags),
            isGenerated,
          };
    } else {
      const tagsList = [...leftTags, ...rightTags];
      // do not shuffle if activity is already answered
      return isAnswered
        ? { tagsList, isGenerated }
        : { tagsList: shuffleArray(tagsList), isGenerated };
    }
  }
}
