export const activityDisplayCorrectionMode = {
  NONE: 0,
  DISPLAY_RESULT: 1,
  DISPLAY_CORRECTION: 2,
  DISPLAY_ERRORS: 3,
  DISPLAY_SCORE: 4,
};

export const activityShowResultDisplayModeQuiz = {
  SCORE: 0,
  STARS: 1,
  DIAGRAM: 2,
};

export const RESULT_MODE_MAP = {
  0: 'NONE',
  1: 'RESULT',
  2: 'CORRECTION',
  3: 'ERRORS',
  4: 'SCORE',
};
export const activityNavigationMode = {
  CONSULTED_ITEMS: 0,
  CURRENT_ITEM_ONLY: 1,
  FREE: 2,
};
export const displayMode = {
  UNDEFINED: 0,
  CHECKBOXES: 1,
  BUTTONS: 2,
  MOVING_TAGS: 3,
  DRAG_DROP: 4,
  KEYBOARD: 5,
  SHORT_TEXT: 6,
  LONG_TEXT: 7,
  NUMBER_TEXT: 8,
  MEMORY: 9,
  PAIRS: 10,
  ASSOCIATE: 11,
};

export const defaultShowResultDisplayModeQuiz = (theme) => {
  // check if the theme is defined and has a property languageSuffix
  if (theme?.languageSuffix === 'dominos') {
    // if the theme is dominos return STARS
    return activityShowResultDisplayModeQuiz.STARS;
  }
  // if the theme is not dominos or theme is undefined return SCORE
  return activityShowResultDisplayModeQuiz.SCORE;
};
