/* eslint-disable max-classes-per-file */
import { QuestionEvaluationModel } from 'core/models/elements/evaluations/questionEvaluation.abstract.model';

/**
 * @class Defines an evaluation of a scorm question
 */
export class ScormEvaluationModel extends QuestionEvaluationModel {
  /**
   * @constructor Creates an instance of scorm evaluation.
   * @param {scormQuestion} scormQuestion is the element to be evaluated.
   */
  constructor(scormQuestion) {
    super(scormQuestion);
  }
}
