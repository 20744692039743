/* eslint-disable max-classes-per-file */
import { QuestionEvaluationModel } from 'core/models/elements/evaluations/questionEvaluation.abstract.model';

/**
 * @class Defines an evaluation of an associate question
 */
export class AssociateEvaluationModel extends QuestionEvaluationModel {
  /**
   * @constructor Creates an instance of an associate evaluation.
   * @param {associateQuestion} associateQuestion is the element to be evaluated.
   */
  constructor(associateQuestion) {
    super(associateQuestion);
    this.results = [];
  }
}

/**
 * @class Defines an evaluation result for an associate question item
 */
export class AssociateEvaluationResultModel {
  /**
   * @constructor Creates an instance of an associate evaluation result.
   */
  constructor() {
    this.itemId = null;
    this.isCorrect = false;
    this.correctItemId = null;
    this.associateId = null;
  }
}
