/**
 * @class Defines a hint.
 */
export class RulesModel {
  /**
   * @constructor Creates an instance of hint built from an original data from the API.
   * @param {*} bo is the basic object come from API.
   */
  constructor(bo) {
    this.action = bo.action;
    this.actionValue = bo.action_value;
    this.condition = bo.condition;
    this.conditionValue = bo.condition_value;
  }
}
