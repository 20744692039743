import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalImage: null,
};

export const imageModalSlice = createSlice({
  name: 'imageModalState',
  initialState,
  reducers: {
    setModalImage: (state, action) => ({
      ...state,
      modalImage: action.payload,
    }),
  },
});

export const { setModalImage } = imageModalSlice.actions;
export default imageModalSlice.reducer;
