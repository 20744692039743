/* eslint-disable eqeqeq */
/* eslint-disable no-useless-constructor */
/* eslint-disable no-return-await */
/* eslint-disable class-methods-use-this */
import { ActivityItemService } from 'core/services/activityItem.abstract.service';
import {
  MapEvaluationModel,
  MapEvaluationResultModel,
} from 'core/models/elements/evaluations/mapEvaluation.model';
import {
  LegendModel,
  MapQuestionModel,
} from 'core/models/elements/questions/mapQuestion.model';
import { shuffleArray, normalizeText } from 'utils/common.util';
import { MapQuestionAnswerModel } from 'core/models/elements/answers/mapQuestionAnswer.model';
import { MapQuestionAnswerCommand } from 'core/commands/elements/answers/mapQuestionAnswer.command';
import { EVALUATION_STATUS } from '../../../constants';
import { TagModel } from 'core/models/elements/components/tag.model';
import { activityItemStates } from 'utils/activityItem.util';

export class MapQuestionService extends ActivityItemService {
  constructor(env) {
    super(env);
    this.evaluationMethod = EVALUATION_STATUS.NONE;
  }

  getExpectedAnswerAsText(legends) {
    return legends.join('|');
  }

  async answer(activitySessionId, activityItemId, mapQuestionAnswer) {
    if (!(mapQuestionAnswer instanceof MapQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "MapQuestionAnswerModel"',
      );
    }

    const mapQuestionAnswerCommand = new MapQuestionAnswerCommand(
      mapQuestionAnswer,
    );
    return await super.answer(
      activitySessionId,
      activityItemId,
      mapQuestionAnswerCommand,
    );
  }

  evaluate(mapQuestion, mapQuestionAnswer) {
    if (!(mapQuestion instanceof MapQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "MapQuestionModel"',
      );
    }
    if (!(mapQuestionAnswer instanceof MapQuestionAnswerModel)) {
      throw new TypeError(
        'The answer must be an instance of class "MapAnswerModel"',
      );
    }

    const evaluation = new MapEvaluationModel(mapQuestion);

    const expectedTags = mapQuestion?.legends?.filter(
      (legend) => !legend?.isDecoy,
    );
    const totalItemsInQuestion = expectedTags?.length;
    const hasItemsInQuestion = totalItemsInQuestion > 0;
    let isCorrect = hasItemsInQuestion;
    let isAnswerComplete = hasItemsInQuestion;
    let nbCorrectAnswers = 0;

    evaluation.results = expectedTags?.map((item, idx) => {
      const expectedTag = new LegendModel(item);
      const answeredTag = mapQuestionAnswer?.items?.[idx];
      const result = new MapEvaluationResultModel();
      result.itemId = answeredTag?.id;
      result.currentAnswer = answeredTag?.text;
      result.correctItemId = expectedTag?.id;
      result.correctAnswer = expectedTag?.text;

      if (mapQuestion?.writeMode) {
        const correctAnswerRegexp = new RegExp(
          `^${normalizeText(
            expectedTag?.text,
            expectedTag.isAccentSensitive,
          )}$`,
          `${expectedTag.isCaseSensitive ? '' : 'i'}`,
        );
        const answerText = answeredTag?.text;
        result.isCorrect = [
          answeredTag,
          ...(expectedTag?.alternativeAnswers || []),
        ].some(({ text, isAccentSensitive } = {}) => {
          const textToCheck = normalizeText(text, isAccentSensitive);

          return correctAnswerRegexp.test(textToCheck);
        });
        result.isNoAnswer = !answerText;
      } else {
        result.isCorrect = expectedTag.id === answeredTag?.id;
        result.isNoAnswer = !answeredTag?.id;
      }
      nbCorrectAnswers += result.isCorrect ? 1 : 0;
      isCorrect = isCorrect && result.isCorrect;
      isAnswerComplete = isAnswerComplete && !result.isNoAnswer;

      return result;
    });
    evaluation.isAnswerComplete = isAnswerComplete;
    evaluation.isCorrect = isCorrect;

    if (hasItemsInQuestion) {
      const exactScoreRatio = nbCorrectAnswers / totalItemsInQuestion;
      evaluation.rawScore = exactScoreRatio * mapQuestion.gradingScale;
      evaluation.scaledScore = exactScoreRatio;
    } else {
      evaluation.rawScore = mapQuestion.gradingScale;
      evaluation.scaledScore = 1;
    }

    return evaluation;
  }

  prepareAnswerAndEvaluation(item, answers) {
    const itemToEvaluate = JSON.parse(JSON.stringify(item));

    const answer = new MapQuestionAnswerModel({
      quizItemId: itemToEvaluate.id,
      answers,
    });

    answer.items = itemToEvaluate?.legends
      ?.filter(({ isDecoy } = {}) => !isDecoy)
      .map(({ id: spot_id }) => {
        const [answerTag = {}] = (answers && answers[spot_id]) || [];
        return new TagModel(answerTag);
      });

    const evaluation = this.evaluate(item, answer);

    return {
      answer,
      evaluation,
    };
  }

  generateTagsObject(question, evaluationState) {
    if (!(question instanceof MapQuestionModel)) {
      throw new TypeError(
        'The question must be an instance of class "MapQuestionModel"',
      );
    }

    let tagsList = [];
    let answers = {};
    const tagsObject = {
      tagsList: [],
      answers: {},
    };
    question.legends.forEach((answer) => {
      tagsList = tagsList.concat(answer);
      if (!answer.isIntrusive && !answer.isDecoy) {
        answers[answer.id] = [];
      }
    });

    // do not shuffle if activity is already answered
    if (evaluationState === activityItemStates.NOT_ANSWERED) {
      tagsList = shuffleArray(tagsList);
    }
    tagsObject.tagsList = tagsList;
    tagsObject.answers = answers;

    return tagsObject;
  }
}
