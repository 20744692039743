/* eslint-disable max-classes-per-file */
import { QuestionModel } from 'core/models/elements/questions/question.abstract.model';
import { TagModel } from 'core/models/elements/components/tag.model';

/**
 * @class Defines a map bo.
 */
export class MapQuestionModel extends QuestionModel {
  /**
   * @constructor Creates an instance of a map question built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.srcBackgroundImage = bo.source_background_image;
    this.legends = bo.legends
      ? bo.legends.map((legend) => new LegendModel(legend))
      : undefined;
    this.scaleX = bo.scale_x;
    this.writeMode = bo.write_mode;
    this.isCaseSensitive = bo.is_case_sensitive;
    this.isAccentSensitive = bo.is_accent_sensitive;
    this.fontsize = bo.font_size;
    this.fontcolor = bo.font_color;
    this.width = bo.width;
    this.height = bo.height;
  }
}

/**
 * @class Defines a legend in the context of a map question.
 */
export class LegendModel extends TagModel {
  /**
   * @constructor Creates an instance of a legend built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.left = bo.left;
    this.top = bo.top;
    this.isDecoy = bo.is_decoy;
  }
}
