import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { combineReducers } from 'redux';
import storageSession from 'redux-persist/lib/storage/session';

import activitySessionSlicer from 'store/activitySession.slice';
import authenticationSlicer from 'store/authentication.slice';
import hintsSlicer from 'store/hints.slice';
import audioSlicer from 'store/audio.slice';
import evaluationsSlicer from 'store/evaluations.slice';
import avatarMessageSlicer from 'store/avatarMessage.slice';
import sessionTimeStateSlices from 'store/sessionTimeState.slice';
import userSlice from 'store/user.slice';
import accessibilityMenuSlice from './accessibilityMenu.slice';
import keyboardSlice from './keyboard.slice';
import latexKeyboardSlice from './latexKeyboard.slice';
import voiceAssistantSlice from './voiceAssistant.slice';
import imageModalSlice from './imageModal.slice';
import saveSlice from './save.slice';
import ltiSlice from './lti.slice';
import navigationMapSlice from './navigationMap.slice';
import headerSlice from './header.slice';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  blacklist: ['accessibility', 'header'],
};

export const reducers = combineReducers({
  activitySession: activitySessionSlicer,
  authentication: authenticationSlicer,
  hints: hintsSlicer,
  audio: audioSlicer,
  evaluations: evaluationsSlicer,
  sessionTimeState: sessionTimeStateSlices,
  avatarMessage: avatarMessageSlicer,
  user: userSlice,
  accessibility: accessibilityMenuSlice,
  keyboard: keyboardSlice,
  latexKeyboard: latexKeyboardSlice,
  voiceAssistant: voiceAssistantSlice,
  imageModal: imageModalSlice,
  save: saveSlice,
  lti: ltiSlice,
  navigationMap: navigationMapSlice,
  header: headerSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});
