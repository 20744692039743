import { createSlice } from '@reduxjs/toolkit';

const avatarMessageModel = {
  category: '',
  text: '',
  oldText: '',
  evaluationMessage: '',
  messageTimeoutId: null,
};

export const avatarMessageSlice = createSlice({
  name: 'avatarMessage',
  initialState: avatarMessageModel,
  reducers: {
    sendAvatarMessage: (state, action) => {
      return {
        ...state,
        oldText: state.text,
        text: action.payload.text,
        category: action.payload.category,
        messageTimeoutId: action.payload.messageTimeoutId,
      };
    },
    removeAvatarMessage: (state) => {
      state.category = '';
      state.text = '';
      if (state.messageTimeoutId) {
        clearTimeout(state.messageTimeoutId);
        state.messageTimeoutId = null;
      }
    },
    removeAvatarMessageHistory: () => ({
      ...avatarMessageModel,
    }),
    sendAvatarEvaluationMessage: (state, action) => ({
      ...state,
      evaluationMessage: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  sendAvatarMessage,
  removeAvatarMessage,
  removeAvatarMessageHistory,
  sendAvatarEvaluationMessage,
} = avatarMessageSlice.actions;

export default avatarMessageSlice.reducer;
