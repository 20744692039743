import { AnswerCommand } from 'core/commands/elements/answers/answer.abstract.command';
import { tactileoAnswersDTOTypes } from 'utils/activityItem.util';

export class LtiQuestionAnswerCommand extends AnswerCommand {
  constructor(ltiQuestion) {
    super(tactileoAnswersDTOTypes.LTI_QUESTION, ltiQuestion);
    this.lti_results = ltiQuestion.ltiResults;
    this.scaled_score = ltiQuestion.scaledScore;
    this.is_successful = ltiQuestion.isSuccessful;
  }
}
