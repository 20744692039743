/* eslint-disable max-classes-per-file */
import { AnswerModel } from 'core/models/elements/answers/answer.abstract.model';
import { TagObsoleteModel } from 'core/models/elements/components/tag.model';

import { pascalToCamel } from 'utils/common.util';

/**
 * @class Defines a group answer corresponding to group question.
 */
export class GroupQuestionAnswerModel extends AnswerModel {
  /**
   * @constructor Creates an instance of a group answer built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    super(bo);
    this.groups = bo.groups
      ? bo.groups.map(
          (g, i) => new GroupAnswerModel({ id: bo?.ids?.[i], ...g }),
        )
      : undefined;
  }

  static instantiateFromApi(data) {
    const bo = pascalToCamel(data);
    return new GroupQuestionAnswerModel(bo);
  }
}

export class GroupAnswerModel {
  constructor(bo) {
    this.id = bo.id;
    this.text = bo.text;
    this.urlImage = bo.urlImage;
    this.cssClass = bo.cssClass;
    this.items = bo.items.map((i) => new TagObsoleteModel(i));
  }
}
