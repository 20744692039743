import { createSlice } from '@reduxjs/toolkit';

const latexKeyboardStateModel = {
  isAvailableLatexKeyboard: false,
  isOpenLatexKeyboard: false,
  inputSelected: null,
  inputValue: null,
};

export const latexKeyboardSlice = createSlice({
  name: 'latexKeyboard',
  initialState: latexKeyboardStateModel,
  reducers: {
    updateLatexKeyboardOpenState: (state) => {
      state.isOpenLatexKeyboard = !state.isOpenLatexKeyboard;
    },
    updateLatexInputSelected: (state, action) => {
      if (action.payload.inputSelected) {
        state.inputSelected = action.payload.inputSelected;
        state.inputValue = action.payload.inputValue;
      }
    },
    setIsAvailableLatexKeyboardState: (state, action) => {
      state.isAvailableLatexKeyboard = action.payload.isAvailableLatexKeyboard;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateLatexKeyboardOpenState,
  updateLatexInputSelected,
  setIsAvailableLatexKeyboardState,
} = latexKeyboardSlice.actions;

export default latexKeyboardSlice.reducer;
