/**
 * @class Defines an answer.
 * The class is abstract and should be extended.
 */
export class AnswerModel {
  /**
   * @constructor Creates an instance of an answer item built from an original data from the API.
   * @param {bo} bo is the basic object come from API.
   */
  constructor(bo) {
    if (this.constructor === AnswerModel) {
      throw new TypeError(
        'Abstract class "AnswerModel" cannot be instantiated directly',
      );
    }

    this.activityItemId = bo.quizItemId;
    this.timeToAnswer = bo.timeToAnswer;
    this.startDate = bo.startDate;
    this.endDate = bo.endDate;
    this.markedForManualEvaluation = bo.markedForManualEvaluation;
    this.rawScoreOverride = bo.rawScoreOverride;
    this.evaluationComment = bo.evaluationComment;
    this.savedResultPath = bo.savedResultPath;
  }
}
